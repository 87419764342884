// src/pages/FindYourCarPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import FindYourCar from '../components/FindYourCar';

const FindYourCarPage = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/ai');
    };

    return (
        <div className="chatbot-layout">
            <div className="sidebar">
                {/* <button className="ios-back-button" onClick={handleBack}>
                    ← Back
                </button> */}
            </div>
            <div className="main-content">
                <FindYourCar onBack={handleBack} />
            </div>
        </div>
    );
};

export default FindYourCarPage;
