import React from 'react';
// Import your icons
import fireIcon from '../assets/fire.png';
import chatBubbleIcon from '../assets/chat-bubble-bottom-center-text.png';
import listBulletIcon from '../assets/list-bullet.png';

const SIDE_MENU = ['Popular', 'Recent', 'All'];

// Map from name => icon
const sideMenuIcons = {
  'Popular': fireIcon,
  'Recent': chatBubbleIcon,
  'All': listBulletIcon
};

function CommunitySidebar({ activeSideTab, onSideTabClick }) {
  return (
    <ul className="side-menu">
      {SIDE_MENU.map((tab) => {
        const iconSrc = sideMenuIcons[tab];
        return (
          <li
            key={tab}
            className={activeSideTab === tab ? 'active' : ''}
            onClick={() => onSideTabClick(tab)}
          >
            <img
              src={iconSrc}
              alt={tab}
              style={{ width: '16px', marginRight: '8px' }}
            />
            {tab}
          </li>
        );
      })}
    </ul>
  );
}

export default CommunitySidebar;
