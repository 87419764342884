// src/components/AskQarby.js
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import './AskQarby.css';

const AskQarby = () => {
  const [conversation, setConversation] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [isChatLoading, setIsChatLoading] = useState(false);

  // Send a message to Azure OpenAI
  const handleSendMessage = async () => {
    if (!currentMessage.trim()) return;

    // 1) Add user message to conversation
    setConversation(prev => [
      ...prev,
      { role: 'user', content: currentMessage }
    ]);
    const userMessage = currentMessage;
    setCurrentMessage('');
    setIsChatLoading(true);

    // 2) System prompt (customize as needed)
    const systemPrompt = `You are Qarby, an enthusiastic car-obsessed friend...
      [Your custom instructions here]
    `;

    try {
      // 3) Call your Azure Function
      const response = await fetch('https://carprojfunctionappnew.azurewebsites.net/api/openaicall', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          systemPrompt,
          conversation,
          userMessage
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const assistantResponse = data.choices[0].message.content;

      // Split the assistant’s response into sentences using a regex.
      const sentences = assistantResponse.split(/(?<=[.!?])\s+/);
      let bubbles = [];

      // Find the first sentence that ends with a question mark.
      const questionIndex = sentences.findIndex(sentence =>
        sentence.trim().endsWith('?')
      );

      if (questionIndex === -1) {
        // No question sentence found: use the full response in one bubble.
        bubbles.push(assistantResponse);
      } else {
        // If there are sentences before the question, group them into one bubble.
        const statementPart = sentences.slice(0, questionIndex).join(' ');
        // And group the question sentence and any following sentences into a second bubble.
        const questionPart = sentences.slice(questionIndex).join(' ');

        if (statementPart.trim().length > 0) {
          bubbles.push(statementPart);
        }
        if (questionPart.trim().length > 0) {
          bubbles.push(questionPart);
        }
      }

      // Append each bubble to the conversation with a delay.
      bubbles.forEach((bubble, index) => {
        setTimeout(() => {
          setConversation(prev => [
            ...prev,
            { role: 'assistant', content: bubble }
          ]);
          // Turn off the typing indicator after the last bubble is added.
          if (index === bubbles.length - 1) {
            setIsChatLoading(false);
          }
        }, index * 500); // 500ms delay per bubble; adjust as desired.
      });
    } catch (error) {
      console.error('Error:', error);
      setConversation(prev => [
        ...prev,
        {
          role: 'assistant',
          content:
            'I apologize, but I encountered an error. Please try again later.'
        }
      ]);
      setIsChatLoading(false);
    }
  };

  // Handle Enter key (unless Shift is pressed)
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="askqarby-wrapper">
      {/* Conversation messages */}
      <div className="askqarby-conversation">
        {conversation.map((msg, i) => (
          <div
            key={i}
            className={msg.role === 'user' ? 'user-message' : 'assistant-message'}
          >
            <ReactMarkdown>{msg.content}</ReactMarkdown>
          </div>
        ))}
        {isChatLoading && (
          <div className="assistant-message">
            <em>Qarby is typing...</em>
          </div>
        )}
      </div>

      {/* Input container with dynamic classes:
          - "glow" when no conversation exists (pre-chat)
          - "blue" after a conversation starts */}
      <div className={`askqarby-input-container ${conversation.length === 0 ? 'glow' : 'blue'}`}>
        <input
          type="text"
          placeholder="Chat with Qarby..."
          value={currentMessage}
          onChange={(e) => setCurrentMessage(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button onClick={handleSendMessage} disabled={isChatLoading}>
          ➤
        </button>
      </div>
    </div>
  );
};

export default AskQarby;


// // src/components/AskQarby.js
// import React, { useState } from 'react';
// import ReactMarkdown from 'react-markdown';
// import './AskQarby.css';

// const AskQarby = () => {
//   const [conversation, setConversation] = useState([]);
//   const [currentMessage, setCurrentMessage] = useState('');
//   const [isChatLoading, setIsChatLoading] = useState(false);

//   // Send a message to Azure OpenAI
//   const handleSendMessage = async () => {
//     if (!currentMessage.trim()) return;

//     // 1) Add user message to conversation
//     setConversation(prev => [
//       ...prev,
//       { role: 'user', content: currentMessage }
//     ]);
//     const userMessage = currentMessage;
//     setCurrentMessage('');
//     setIsChatLoading(true);

//     // 2) System prompt (customize as needed)
//     const systemPrompt = `You are Qarby, an enthusiastic car-obsessed friend...
//       [Your custom instructions here]
//     `;

//     try {
//       // 3) Call your Azure Function
//       const response = await fetch('https://carprojfunctionappnew.azurewebsites.net/api/openaicall', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           systemPrompt,
//           conversation,
//           userMessage
//         })
//       });

//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }

//       const data = await response.json();
//       const assistantResponse = data.choices[0].message.content;

//       // Split the assistant’s response into sentences using a regex.
//       const sentences = assistantResponse.split(/(?<=[.!?])\s+/);
//       let bubbles = [];

//       // Find the first sentence that ends with a question mark.
//       const questionIndex = sentences.findIndex(sentence =>
//         sentence.trim().endsWith('?')
//       );

//       if (questionIndex === -1) {
//         // No question sentence found: use the full response in one bubble.
//         bubbles.push(assistantResponse);
//       } else {
//         // If there are sentences before the question, group them into one bubble.
//         const statementPart = sentences.slice(0, questionIndex).join(' ');
//         // And group the question sentence and any following sentences into a second bubble.
//         const questionPart = sentences.slice(questionIndex).join(' ');

//         if (statementPart.trim().length > 0) {
//           bubbles.push(statementPart);
//         }
//         if (questionPart.trim().length > 0) {
//           bubbles.push(questionPart);
//         }
//       }

//       // Append each bubble to the conversation with a delay.
//       bubbles.forEach((bubble, index) => {
//         setTimeout(() => {
//           setConversation(prev => [
//             ...prev,
//             { role: 'assistant', content: bubble }
//           ]);
//           // Turn off the typing indicator after the last bubble is added.
//           if (index === bubbles.length - 1) {
//             setIsChatLoading(false);
//           }
//         }, index * 500); // 500ms delay per bubble; adjust as desired.
//       });
//     } catch (error) {
//       console.error('Error:', error);
//       setConversation(prev => [
//         ...prev,
//         {
//           role: 'assistant',
//           content:
//             'I apologize, but I encountered an error. Please try again later.'
//         }
//       ]);
//       setIsChatLoading(false);
//     }
//   };

//   // Handle Enter key to send the message (unless Shift is pressed)
//   const handleKeyPress = (e) => {
//     if (e.key === 'Enter' && !e.shiftKey) {
//       e.preventDefault();
//       handleSendMessage();
//     }
//   };

//   return (
//     <div className="askqarby-wrapper">
//       {/* Conversation messages */}
//       <div className="askqarby-conversation">
//         {conversation.map((msg, i) => (
//           <div
//             key={i}
//             className={msg.role === 'user' ? 'user-message' : 'assistant-message'}
//           >
//             <ReactMarkdown>{msg.content}</ReactMarkdown>
//           </div>
//         ))}
//         {isChatLoading && (
//           <div className="assistant-message">
//             <em>Qarby is typing...</em>
//           </div>
//         )}
//       </div>

//       {/* Input container */}
//       <div className="askqarby-input-container">
//         <input
//           type="text"
//           placeholder="Chat with Qarby..."
//           value={currentMessage}
//           onChange={(e) => setCurrentMessage(e.target.value)}
//           onKeyPress={handleKeyPress}
//         />
//         <button onClick={handleSendMessage} disabled={isChatLoading}>
//           ➤
//         </button>
//       </div>
//     </div>
//   );
// };

// export default AskQarby;
