import React, { useState, useEffect } from 'react';
import { useUser } from '../UserContext';
import '../styles/Community.css';
import CommunitySidebar from '../components/CommunitySidebar';
import likeIcon from '../assets/like.png';
import commentIcon from '../assets/comment.png';
import shareIcon from '../assets/share.png';

// The 6 topic buttons
const TOPICS = [
  'Car Deal Insights',
  'Car Culture',
  'Test Drive Talk',
  'Wheel Talk',
  'Virtual Garage',
  'Speed Fanatics',
];

// The side menu
// const SIDE_MENU = ['Popular', 'Recent', 'All'];

function Community() {
  const { user } = useUser() || {};
  // We store sideTab in `activeSideTab`, or `null` if user picked a topic
  // We store selectedTopic for the horizontal bar, or `null` if user picked side tab
  const [activeSideTab, setActiveSideTab] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);

  // The fetched posts
  const [posts, setPosts] = useState([]);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [error, setError] = useState(null);

  // For new post
  const [newContent, setNewContent] = useState('');

  // For editing
  const [editingPostId, setEditingPostId] = useState(null);
  const [editContent, setEditContent] = useState('');

  // Single comment input
  const [commentText, setCommentText] = useState('');

  // For the 3-dot menu
  const [menuPostId, setMenuPostId] = useState(null);

  // Toggling comment visibility
  const [visibleComments, setVisibleComments] = useState({});

  // 1) Fetch posts whenever sideTab or selectedTopic changes
  useEffect(() => {
    const fetchPosts = async () => {
      setLoadingPosts(true);
      setError(null);
      try {
        // Determine which "topic" param to pass to server
        // If user selected a side tab => we fetch ALL topics => topic=''
        // If user selected a horizontal topic => we fetch that topic => topic='someTopic'
        let actualTopic = '';
        if (activeSideTab === null && selectedTopic) {
          // user picked a topic from the bar
          actualTopic = selectedTopic;
        }
        // otherwise sideTab is not null => fetch all => actualTopic = ''

        const url = `https://carprojfunctionappnew.azurewebsites.net/api/community?topic=${encodeURIComponent(actualTopic)}`;
        const res = await fetch(url);
        if (!res.ok) {
          throw new Error(`Error fetching posts, status: ${res.status}`);
        }
        const data = await res.json();
        let fetchedPosts = data.posts || [];

        // Now sort based on sideTab
        if (activeSideTab === 'Popular') {
          fetchedPosts.sort((a, b) =>
            (b.likesCount + b.commentsCount) - (a.likesCount + a.commentsCount)
          );
        } else if (activeSideTab === 'Recent') {
          // Sort by date descending
          fetchedPosts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        } else if (activeSideTab === 'All') {
          // Maybe we do the same recency sort for "All," or no sort?
          fetchedPosts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        } else if (selectedTopic) {
          // If we have a topic, we can just sort by date
          fetchedPosts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }

        setPosts(fetchedPosts);
      } catch (err) {
        console.error('Fetch posts error:', err);
        setError(err.message);
      } finally {
        setLoadingPosts(false);
      }
    };

    fetchPosts();
  }, [activeSideTab, selectedTopic]);

  // 2) If user picks sideTab => clear selectedTopic
  const handleSideTabClick = (tab) => {
    setActiveSideTab(tab);
    setSelectedTopic(null); // unselect horizontal
  };

  // 3) If user picks a topic => clear sideTab
  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
    setActiveSideTab(null);
  };

  // 4) New post
  const handleAddPost = async () => {
    if (!newContent.trim()) return;
    try {
      // If user selected a sideTab => means "all" => no single topic?
      // Actually we want them to pick a topic? Or we do nothing if sideTab is not null?
      // For this example, if sideTab is set => we just treat it as no topic => can't post?
      // Let's assume user must pick a topic from the bar to post (or we default to "General"?)
      let theTopic = selectedTopic || ''; // if sideTab is chosen => this is empty
      if (!theTopic) {
        alert('Please select one of the 6 topics before posting.');
        return;
      }
      const body = {
        topic: theTopic,
        content: newContent,
        userId: user?.id || 'anonymous',
        authorName: user?.displayName || 'Anonymous'
      };
      const res = await fetch('https://carprojfunctionappnew.azurewebsites.net/api/community', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
      if (!res.ok) throw new Error(`Error creating post: ${res.status}`);

      const result = await res.json();
      setPosts(prev => [result.post, ...prev]);
      setNewContent('');
    } catch (err) {
      console.error('Add post error:', err);
      alert('Error adding post: ' + err.message);
    }
  };

  // 5) Edit / Delete
  const startEditing = (post) => {
    setEditingPostId(post._id);
    setEditContent(post.content);
    setMenuPostId(null);
  };
  const cancelEdit = () => {
    setEditingPostId(null);
    setEditContent('');
  };
  const saveEdit = async (post) => {
    if (!editContent.trim()) return;
    try {
      const patchUrl = `https://carprojfunctionappnew.azurewebsites.net/api/community?postId=${encodeURIComponent(post._id)}`;
      const body = {
        action: 'edit',
        userId: user?.id || '',
        newContent: editContent
      };
      const res = await fetch(patchUrl, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
      if (!res.ok) throw new Error(`Edit failed: ${res.status}`);

      const updated = await res.json();
      setPosts(prev => prev.map(p => p._id === updated.post._id ? updated.post : p));
      setEditingPostId(null);
      setEditContent('');
    } catch (err) {
      console.error('Edit post error:', err);
      alert(err.message);
    }
  };
  const handleDeletePost = async (post) => {
    setMenuPostId(null);
    if (!window.confirm('Are you sure you want to delete this post?')) return;
    try {
      const url = `https://carprojfunctionappnew.azurewebsites.net/api/community?postId=${encodeURIComponent(post._id)}&userId=${encodeURIComponent(user?.id || '')}`;
      const res = await fetch(url, { method: 'DELETE' });
      if (!res.ok) throw new Error(`Delete failed: ${res.status}`);
      setPosts(prev => prev.filter(p => p._id !== post._id));
    } catch (err) {
      console.error('Delete post error:', err);
      alert(err.message);
    }
  };

  // 6) Toggle post-like
  const handleLike = async (post) => {
    if (!user?.id) {
      alert('Please login to like posts.');
      return;
    }
    try {
      const patchUrl = `https://carprojfunctionappnew.azurewebsites.net/api/community?postId=${encodeURIComponent(post._id)}`;
      const body = { action: 'like', userId: user.id };
      const res = await fetch(patchUrl, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
      if (!res.ok) throw new Error(`Like failed: ${res.status}`);

      const updated = await res.json();
      setPosts(prev => prev.map(p => p._id === updated.post._id ? updated.post : p));
    } catch (err) {
      console.error('Like error:', err);
      alert(err.message);
    }
  };

  // 7) Add a comment
  const handleAddComment = async (post) => {
    if (!user?.id) {
      alert('Please login to comment.');
      return;
    }
    if (!commentText.trim()) return;
    try {
      const patchUrl = `https://carprojfunctionappnew.azurewebsites.net/api/community?postId=${encodeURIComponent(post._id)}`;
      const body = {
        action: 'comment',
        userId: user.id,
        authorName: user?.displayName || 'Anonymous',
        commentText
      };
      const res = await fetch(patchUrl, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
      if (!res.ok) throw new Error(`Comment failed: ${res.status}`);

      const updated = await res.json();
      setPosts(prev => prev.map(p => p._id === updated.post._id ? updated.post : p));
      setCommentText('');
    } catch (err) {
      console.error('Comment error:', err);
      alert(err.message);
    }
  };

  // 8) Like a comment
  const handleLikeComment = async (post, comment) => {
    if (!user?.id) {
      alert('Please login to like comments.');
      return;
    }
    if (!comment.commentId) {
      alert('Cannot like an older comment with no commentId');
      return;
    }
    try {
      const patchUrl = `https://carprojfunctionappnew.azurewebsites.net/api/community?postId=${encodeURIComponent(post._id)}`;
      const body = {
        action: 'likeComment',
        userId: user.id,
        commentId: comment.commentId
      };
      const res = await fetch(patchUrl, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
      if (!res.ok) throw new Error(`Like comment failed: ${res.status}`);
      const updated = await res.json();
      setPosts(prev => prev.map(p => p._id === updated.post._id ? updated.post : p));
    } catch (err) {
      console.error('Like comment error:', err);
      alert(err.message);
    }
  };

  // 9) Show/hide comments
  const handleToggleComments = (postId) => {
    setVisibleComments(prev => ({
      ...prev,
      [postId]: !prev[postId]
    }));
  };

  // show/hide 3-dot menu
  const toggleMenuForPost = (postId) => {
    setMenuPostId(curr => (curr === postId ? null : postId));
  };

  // Format date
  const formatDate = (dateStr) => dateStr ? new Date(dateStr).toLocaleString() : 'some time ago';

  return (
    <div className="community-container">
      {/* Use the new sidebar component instead of manually mapping side-menu */}
      <aside className="community-sidebar">
      <h2 className="community-heading">Community</h2>
        <CommunitySidebar
          activeSideTab={activeSideTab}
          onSideTabClick={handleSideTabClick}
        />
      </aside>

      {/* Main content */}
      <main className="community-main">
        {/* Horizontal topics (centered) */}
        <div className="topic-bar">
          {TOPICS.map((t) => (
            <button
              key={t}
              className={`topic-btn ${selectedTopic === t ? 'selected' : ''}`}
              onClick={() => handleTopicClick(t)}
            >
              {t}
            </button>
          ))}
        </div>

        {/* New post form */}
        <div className="new-post-form">
          <img
            className="user-avatar"
            src="https://placehold.co/40?text=Avatar"
            alt="User avatar"
          />
          <div className="post-input-container">
            <textarea
              placeholder="Add your opinion"
              value={newContent}
              onChange={(e) => setNewContent(e.target.value)}
            />
            <div className="post-form-actions">
              <button onClick={handleAddPost}>Post</button>
            </div>
          </div>
        </div>

        {/* Post feed */}
        <div className="feed-container">
          {loadingPosts && <p>Loading posts...</p>}
          {error && <p style={{ color: 'red' }}>{error}</p>}

          {!loadingPosts && !error && posts.map((post) => {
            const isEditing = (editingPostId === post._id);
            const isOwner = (post.userId === user?.id);
            const showComments = !!visibleComments[post._id];
            return (
              <div key={post._id} className="post-card">
                {/* Header */}
                <div className="post-header">
                  <div className="post-user-info">
                    <img
                      className="post-avatar"
                      src={post.authorAvatar || 'https://placehold.co/40?text=User'}
                      alt={post.authorName || 'User'}
                    />
                    <div className="author-time">
                      <h5>{post.authorName || 'Anonymous'}</h5>
                      <span>
                        {formatDate(post.createdAt)}
                        {post.edited && <em> (edited)</em>}
                      </span>
                    </div>
                  </div>
                  <div
                    className="post-dots"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleMenuForPost(post._id);
                    }}
                  >
                    <span style={{ cursor: 'pointer' }}>...</span>
                    {menuPostId === post._id && (
                      <div className="dots-menu">
                        {isOwner && !isEditing && (
                          <>
                            <button onClick={() => startEditing(post)}>Edit</button>
                            <button onClick={() => handleDeletePost(post)}>Delete</button>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {/* Content */}
                <div className="post-content">
                  {isEditing ? (
                    <div className="edit-mode">
                      <textarea
                        value={editContent}
                        onChange={(e) => setEditContent(e.target.value)}
                      />
                      <div className="edit-buttons">
                        <button onClick={() => saveEdit(post)}>Save</button>
                        <button onClick={cancelEdit}>Cancel</button>
                      </div>
                    </div>
                  ) : (
                    <p>{post.content}</p>
                  )}
                </div>

                {/* Footer */}
                <div className="post-footer">
                  <button onClick={() => handleLike(post)}>
                    <img
                      src={likeIcon}
                      alt="like"
                      style={{ width: '16px', marginRight: '4px' }}
                    />
                    {post.likesCount || 0}
                  </button>

                  <button onClick={() => handleToggleComments(post._id)}>
                    <img
                      src={commentIcon}
                      alt="comment"
                      style={{ width: '16px', marginRight: '4px' }}
                    />
                    {post.commentsCount || 0}
                  </button>

                  <button>
                    <img
                      src={shareIcon}
                      alt="share"
                      style={{ width: '16px', marginRight: '4px' }}
                    />
                    Share
                  </button>
                </div>

                {/* If we are showing comments: comment input + list */}
                {showComments && (
                  <>
                    <div className="comment-input-wrapper">
                      <input
                        type="text"
                        placeholder="Add your Comment"
                        value={commentText}
                        onChange={(e) => setCommentText(e.target.value)}
                      />
                      <button onClick={() => handleAddComment(post)}>Send</button>
                    </div>
                    {/* comment list */}
                    {post.comments && post.comments.length > 0 && (
                      <div className="comment-list">
                        {post.comments.map((c, idx) => {
                          // fallback key if older comment has no commentId
                          const commentKey = c.commentId || `old-${idx}`;
                          return (
                            <div className="comment-item" key={commentKey}>
                              <div className="comment-author-avatar">
                                <img
                                  src="https://placehold.co/30?text=User"
                                  alt="comment author"
                                />
                              </div>
                              <div className="comment-details">
                                <h6>
                                  {c.authorName || 'Anonymous'}
                                  <span> • {formatDate(c.commentedAt)}</span>
                                </h6>
                                <p>{c.text}</p>
                                <div className="comment-footer">
                                  <button onClick={() => handleLikeComment(post, c)}>

                                    <img
                                      src={likeIcon}
                                      alt="like"
                                      style={{ width: '16px', marginRight: '4px' }}
                                    />
                                    {c.likesCount || 0}
                                  </button>
                                  <button>

                                    <img
                                      src={commentIcon}
                                      alt="comment"
                                      style={{ width: '16px', marginRight: '4px' }}
                                    />
                                    0
                                  </button>
                                  <button>

                                    <img
                                      src={shareIcon}
                                      alt="share"
                                      style={{ width: '16px', marginRight: '4px' }}
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      </main>
    </div>
  );
}

export default Community;