import React from "react";
import styles from "./CommunityCTA.module.css";

const CommunityCTA = () => {
  return (
    <div className={styles["community-cta-background"]}>
      <div className={styles["community-cta"]}>
        <div className={styles["community-cta-title"]}>
          Join Qarby Community
        </div>

        <div className={styles["community-avatars"]}>
          <img
            className={styles["avatar-img"]}
            alt="Ellipse 1"
            src="https://cdn.animaapp.com/projects/678a985e0cccaf0892a06cc5/releases/678a99f1cefd91045472e2fe/img/ellipse-1.svg"
          />
          <img
            className={styles["avatar-img"]}
            alt="Ellipse 3"
            src="https://cdn.animaapp.com/projects/678a985e0cccaf0892a06cc5/releases/678a99f1cefd91045472e2fe/img/ellipse-3.svg"
          />
          <img
            className={styles["avatar-img"]}
            alt="Ellipse 2"
            src="https://cdn.animaapp.com/projects/678a985e0cccaf0892a06cc5/releases/678a99f1cefd91045472e2fe/img/ellipse-2.svg"
          />
          <img
            className={styles["avatar-img"]}
            alt="Ellipse 4"
            src="https://cdn.animaapp.com/projects/678a985e0cccaf0892a06cc5/releases/678a99f1cefd91045472e2fe/img/ellipse-4.svg"
          />
          <img
            className={styles["avatar-img"]}
            alt="Ellipse 8"
            src="https://cdn.animaapp.com/projects/678a985e0cccaf0892a06cc5/releases/678a99f1cefd91045472e2fe/img/ellipse-8.svg"
          />
          <img
            className={styles["avatar-img"]}
            alt="Ellipse 5"
            src="https://cdn.animaapp.com/projects/678a985e0cccaf0892a06cc5/releases/678a99f1cefd91045472e2fe/img/ellipse-5.svg"
          />
          <img
            className={styles["avatar-img"]}
            alt="Ellipse 9"
            src="https://cdn.animaapp.com/projects/678a985e0cccaf0892a06cc5/releases/678a99f1cefd91045472e2fe/img/ellipse-9.svg"
          />
          <img
            className={styles["avatar-img"]}
            alt="Ellipse 6"
            src="https://cdn.animaapp.com/projects/678a985e0cccaf0892a06cc5/releases/678a99f1cefd91045472e2fe/img/ellipse-6.svg"
          />
        </div>

        <p className={styles["community-cta-subtitle"]}>
          Connect with fellow car enthusiasts, access exclusive expert insights,
          and share your journey—from finding your dream car to keeping it in top
          shape. Join us and drive smarter every day.
        </p>

        <div className={styles["join-now-button-wrapper"]}>
          <div className={styles["join-now-button-text"]}>Join Now</div>
        </div>
      </div>
    </div>
  );
};

export default CommunityCTA;
