import React from 'react';
import { Link } from 'react-router-dom';
import './BottomNav.css';
import { HeroiconsOutlineSparkles2 } from '../icons/HeroiconsOutlineSparkles2';
import view360Icon from '../assets/360-view-1.svg';
import msgIcon from '../assets/msg_icon.png';

const BottomNav = () => {
  return (
    <nav className="bottom-nav">
      {/* Chat item: uses nav-icon-bg container with message icon */}
      <Link to="/askqarby" className="nav-item">
        <div className="nav-icon-bg">
          <img src={msgIcon} alt="Chat Icon" className="nav-icon" />
        </div>
      </Link>

      {/* Matchmaker item: uses nav-icon-bg container with sparkles icon */}
      <Link to="/findyourcar" className="nav-item">
        <div className="nav-icon-bg">
          <HeroiconsOutlineSparkles2 className="nav-icon" />
        </div>
      </Link>

      {/* Garage item: uses nav-icon-bg container with 360 view icon */}
      <Link to="/garage" className="nav-item">
        <div className="nav-icon-bg">
          <img src={view360Icon} alt="Garage Icon" className="nav-icon" />
        </div>
      </Link>
    </nav>
  );
};

export default BottomNav;



// import React from 'react';
// import { Link } from 'react-router-dom';
// import './BottomNav.css';

// // React Icons (example icons from AI / FontAwesome / etc.)
// import { AiFillHome } from 'react-icons/ai';
// import { FaCar } from 'react-icons/fa';
// import { FaRobot } from 'react-icons/fa';

// const BottomNav = () => {
//     return (
//         <nav className="bottom-nav">
//             <Link to="/">
//                 <AiFillHome className="icon" />
//                 <span className="label">Home</span>
//             </Link>
//             <Link to="/garage">
//                 <FaCar className="icon" />
//                 <span className="label">Garage</span>
//             </Link>
//             <Link to="/AskOrBuy">
//                 <FaRobot className="icon" />
//                 <span className="label">AI</span>
//             </Link>
//         </nav>
//     );
// };

// export default BottomNav;
