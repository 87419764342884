import React from "react";
import styles from "./MeetQarby.module.css";

const MeetQarby = () => {
  return (
    <div className={styles["meet-qarby-background"]}>
      <div className={styles["meet-qarby-content"]}>
        <h2 className={styles["meet-qarby-title"]}>Meet Qarby</h2>
        <p className={styles["meet-qarby-subtitle"]}>
          Your All-in-One AI Car Assistant. From finding your perfect car to maintaining it for the long haul, 
          Qarby offers trusted information, expert insights, and personalized guidance—making your entire car journey 
          clear and stress‑free.
        </p>
      </div>
    </div>
  );
};

export default MeetQarby;
