import React from "react";
import { Link } from "react-router-dom";
import landingPageVideo from "../assets/landing_page_video.mp4";
import arrowUpRight from "../assets/up_right_arrow.png";
import { HeroiconsOutlineChatBubbleLeftRight2 } from "../icons/HeroiconsOutlineChatBubbleLeftRight2";
import { HeroiconsOutlineSparkles2 } from "../icons/HeroiconsOutlineSparkles2";
import styles from "./FindYourCarSection.module.css";

const FindYourCarSection = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.videoContainer}>
        <video
          src={landingPageVideo}
          autoPlay
          loop
          muted
          playsInline
          className={styles.video}
        />
      </div>
      <div className={styles.whiteOverlay}>
        <div className={styles.overlayContent}>
          <div className={styles.leftContent}>
            <div className={styles.findCarButtonContainer}>
              <Link to="/findyourcar" className={styles.findCarButtonText}>
                Find your Car
              </Link>
              <img
                src={arrowUpRight}
                alt="Arrow"
                className={styles.findCarArrowImg}
              />
            </div>
          </div>
          <div className={styles.rightContent}>
            <div className={styles.matchmakerIconsList}>
              <Link to="/askqarby">
                <div className={styles.matchmakerIconBg}>
                  <HeroiconsOutlineChatBubbleLeftRight2
                    className={styles.matchmakerIcon}
                  />
                </div>
              </Link>
              <Link to="/garage">
                <div className={styles.matchmakerIconBg}>
                  <img
                    className={styles.matchmakerIconImg}
                    alt="360 View"
                    src="https://cdn.animaapp.com/projects/678a985e0cccaf0892a06cc5/releases/678a99f1cefd91045472e2fe/img/360-view-1-1.svg"
                  />
                </div>
              </Link>
              <Link to="/findyourcar">
                <div className={styles.matchmakerIconBg}>
                  <HeroiconsOutlineSparkles2
                    className={styles.matchmakerIcon}
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindYourCarSection;
