/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsOutlineChatBubbleLeftRight2 = ({ className }) => {
  return (
    <svg
      className={`heroicons-outline-chat-bubble-left-right-2 ${className}`}
      fill="none"
      height="42"
      viewBox="0 0 42 42"
      width="42"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M17.6082 26.401C17.6082 27.8323 18.3943 29.1033 19.5825 29.7367C20.0072 29.9631 20.4833 30.108 20.9932 30.1496C23.2589 30.3345 25.5506 30.4288 27.8647 30.4289L32.993 35.5571V30.2737C33.5757 30.2384 34.1567 30.197 34.7361 30.1497C36.6726 29.9917 38.1212 28.3441 38.1212 26.4011V19.0753C38.1212 17.4189 37.0683 15.9766 35.5571 15.4904C35.2983 15.4071 35.026 15.3518 34.7433 15.3284C32.4753 15.1401 30.1812 15.0441 27.8646 15.0441C25.5481 15.0441 23.2541 15.1401 20.9862 15.3284C19.0527 15.4888 17.6082 17.1351 17.6082 19.0753V26.401Z"
        fill="white"
      />

      <path
        className="path"
        d="M35.5571 15.4904C37.0683 15.9766 38.1212 17.4189 38.1212 19.0753V26.4011C38.1212 28.3441 36.6726 29.9917 34.7361 30.1497C34.1567 30.197 33.5757 30.2384 32.993 30.2737V35.5571L27.8647 30.4289C25.5506 30.4288 23.2589 30.3345 20.9932 30.1496C20.4833 30.108 20.0072 29.9631 19.5825 29.7367M35.5571 15.4904C35.2983 15.4071 35.026 15.3518 34.7433 15.3284C32.4753 15.1401 30.1812 15.0441 27.8646 15.0441C25.5481 15.0441 23.2541 15.1401 20.9862 15.3284C19.0527 15.4888 17.6082 17.1351 17.6082 19.0753V26.401C17.6082 27.8323 18.3943 29.1033 19.5825 29.7367M35.5571 15.4904V12.2874C35.5571 9.5157 33.5883 7.11355 30.8396 6.75673C27.3482 6.30348 23.7878 6.0697 20.1728 6.0697C16.5575 6.0697 12.9968 6.30352 9.50496 6.75687C6.75638 7.11372 4.7876 9.51585 4.7876 12.2875V22.929C4.7876 25.7007 6.75639 28.1028 9.50498 28.4597C10.4912 28.5877 11.483 28.6983 12.48 28.791V36.8392L19.5825 29.7367"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
