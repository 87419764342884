// src/components/PrivacyPolicy.js

import React, { useState } from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  // Track open/close state for each section
  const [openSections, setOpenSections] = useState({});

  const sections = [
    {
      title: "Introduction",
      content:
        "Welcome to Qarby. This Privacy Policy explains how we collect, use, and share your personal information when you use our website and services. By using Qarby, you agree to the collection and use of information in accordance with this policy.",
    },
    {
      title: "Information Collection and Use",
      content:
        "We collect personal data such as your name, email address, and phone number when you use our services. Additionally, we gather usage data (e.g., IP address, browser type, and pages visited) to help improve our service.",
    },
    {
      title: "Cookies",
      content:
        "We use cookies and similar tracking technologies to track activity on our Service and hold certain information. You can instruct your browser to refuse cookies, though some parts of our Service may not function properly.",
    },
    {
      title: "Use of Data",
      content:
        "We use the collected data for various purposes, including to provide and maintain our Service, notify you about changes, offer customer support, and monitor the usage of our Service.",
    },
    {
      title: "Security",
      content:
        "The security of your data is important to us. While we strive to use commercially acceptable means to protect your Personal Data, no method of transmission over the Internet or electronic storage is 100% secure.",
    },
    {
      title: "Changes to This Privacy Policy",
      content:
        "We may update our Privacy Policy from time to time. Please review this page periodically for any changes. Changes become effective when they are posted on this page.",
    },
    {
      title: "Contact Us",
      content:
        'If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@qarby.com">info@qarby.com</a>.',
    },
  ];

  const toggleSection = (index) => {
    setOpenSections((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <div className="privacy-policy-page">
      <div className="privacy-policy-container">
        <h1>Privacy Policy</h1>
        {sections.map((section, index) => (
          <div
            key={index}
            className={`collapsible-section ${openSections[index] ? "open" : ""}`}
          >
            <div className="section-header" onClick={() => toggleSection(index)}>
              <h2>{section.title}</h2>
              <span className="toggle-icon">
                {openSections[index] ? "-" : "+"}
              </span>
            </div>
            {openSections[index] && (
              <div className="section-content">
                {/* Using dangerouslySetInnerHTML for the Contact Us link, if needed */}
                {section.title === "Contact Us" ? (
                  <p
                    dangerouslySetInnerHTML={{ __html: section.content }}
                  ></p>
                ) : (
                  <p>{section.content}</p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
