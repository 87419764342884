// src/pages/AboutPage.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/AboutPage.css";

export const AboutPage = () => {
  const [openSections, setOpenSections] = useState({});

  const sections = [
    {
      title: "Our Mission",
      content:
        "At Qarby, our mission is to empower every car owner, buyer, and enthusiast with a seamless, AI-driven experience that transforms the way you interact with the automotive world. We strive to simplify car buying, maintenance, and customization by providing trusted information, expert insights, and personalized guidance at every step of your journey. By leveraging innovative technology, we aim to foster a community where informed decisions and exceptional support lead to a more enjoyable and stress-free car experience for everyone.",
    },
    {
      title: "Our Story",
      content:
        "Qarby began with a simple realization: navigating the world of cars—whether buying, maintaining, or customizing—can be overwhelming. Founded by a group of passionate automotive enthusiasts, we saw firsthand how confusing and stressful the process could be, and we decided there had to be a better way. Combining our love for cars with cutting-edge AI technology, we set out to create a platform that not only simplifies every step of your car journey but also empowers you with trusted insights and expert guidance. Today, Qarby is more than just an app—it’s a community built on transparency, innovation, and a shared commitment to making car ownership a truly enjoyable experience.",
    },
    {
      title: "What We Offer",
      content:
        " At Qarby, we provide an all-in-one platform to revolutionize your car experience. We simplify the car-buying process with personalized recommendations, trusted insights, and expert guidance tailored to your needs. Beyond purchasing, our digital garage helps you keep track of your vehicle’s health, maintenance schedules, and driving habits. For troubleshooting and general car research, our AI-powered chat connects you with reliable answers and even expert advice when needed. And for the enthusiasts, we offer specialized tools like a parts finder and car mods tracker. With Qarby, you get a comprehensive, supportive, and innovative solution for every aspect of your automotive journey.",
    },
    {
      title: "Why Choose Qarby?",
      content:
        " Because your car journey deserves expert support every step of the way. Qarby combines advanced AI with trusted information and personalized insights to make car buying, maintenance, and customization stress-free and informed. Whether you're researching your next vehicle, scheduling routine maintenance, or troubleshooting on the go, our platform delivers tailored guidance and reliable answers when you need them most. Choose Qarby for a smarter, more connected automotive experience where innovation meets passion.",
    },
  ];

  const toggleSection = (index) => {
    setOpenSections((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <div className="about-page">
      <div className="about-page-container">
        <header className="about-header">
          <h1>Welcome to Qarby</h1>
          <p>Car Buying Starts with Qarby</p>
        </header>
        {sections.map((section, index) => (
          <div key={index} className="collapsible-section">
            <div className="section-header" onClick={() => toggleSection(index)}>
              <h2>{section.title}</h2>
              <span className="toggle-icon">
                {openSections[index] ? "–" : "+"}
              </span>
            </div>
            {openSections[index] && (
              <div className="section-content">
                <p>{section.content}</p>
              </div>
            )}
          </div>
        ))}
        <footer className="about-footer">
          <p>
            Have questions? Email us at{" "}
            <a href="mailto:info@qarby.com">info@qarby.com</a>
            <br />
            <br />
            <Link to="/">Home</Link>
          </p>
        </footer>
      </div>
    </div>
  );
};