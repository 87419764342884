// src/components/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import QarbyLogo from '../assets/Qarby_Logo.png'; // Adjust the path if needed

const Footer = () => {
  return (
    <footer className="home-footer">
      <div className="footer-container">
        {/* Left group: Logo, brand name, and copyright */}
        <div className="footer-left">
          <img src={QarbyLogo} alt="Qarby Logo" className="footer-logo" />
          <span className="footer-brand">qarby</span>
          <span className="footer-copy">© 2025 All Rights Reserved</span>
        </div>
        {/* Right group: Navigation links */}
        <div className="footer-right">
          <Link to="/about" className="footer-link">About Us</Link>
          <Link to="/privacy" className="footer-link">Privacy Policy</Link>
          <Link to="/termsandconditions" className="footer-link">Terms &amp; Conditions</Link>
          <Link to="/dealership-portal" className="footer-link">Dealership Portal</Link>
          <a href="mailto:info@qarby.com" className="footer-link">info@qarby.com</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
