import React, { useState, useCallback, useEffect } from 'react';
import { useUser } from '../UserContext'; 
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../loginRequest';
import CarImageCarousel from './CarImageCarousel';
import compareIcon from '../assets/compare_cars.png'; // Import your custom compare icon
import './RecommendationsDisplay.css';

function RecommendationsDisplay({ recommendations, responses, onStartOver }) {
  const { user } = useUser();
  const { instance, accounts } = useMsal();

  const [carListings, setCarListings] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Single-car navigation
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showListings, setShowListings] = useState(false);

  // Force a re-mount of the carousel on each new car => resets to first image
  const [carouselKey, setCarouselKey] = useState(0);

  // Track which cars are favorited
  const [favoritedCars, setFavoritedCars] = useState({});

  // New state for the compare feature:
  const [comparePopupVisible, setComparePopupVisible] = useState(false);
  const [compareTargetCar, setCompareTargetCar] = useState(null);

  const hasRecs = Array.isArray(recommendations) && recommendations.length > 0;

  // (Optional) for "Lease"/"Buy"/"Not sure" => "new"/"used"/"both"
  const determineCondition = (leaseOrBuy) => {
    if (leaseOrBuy === 'Lease') return 'new';
    if (leaseOrBuy === 'Buy') return 'used';
    return 'both';
  };

  // Fetch listings for each recommended car
  const fetchCarListings = useCallback(async (modelName) => {
    try {
      const currentYear = new Date().getFullYear();
      let minYear = currentYear - 10;
      const locationParam = responses.location?.trim() || 'near me';

      const queryParams = {
        model: modelName,
        location: locationParam,
        budgetMin: responses.budgetMin,
        budgetMax: responses.budgetMax,
        minYear: minYear.toString(),
        purpose: 'general',
        condition: determineCondition(responses.leaseOrBuy),
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const url = `https://carprojfunctionappnew.azurewebsites.net/api/getcarlistings?${queryString}`;

      setLoading(true);
      const res = await fetch(url);
      setLoading(false);

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.error || 'Failed to fetch car listings');
      }

      const data = await res.json();
      return data.listings || [];
    } catch (err) {
      console.error(`Error fetching listings for ${modelName}:`, err);
      setError(`Failed to fetch car listings for ${modelName}: ${err.message}`);
      setLoading(false);
      return [];
    }
  }, [responses]);

  // On mount or when recommendations changes => fetch all listings
  useEffect(() => {
    const fetchAllListings = async () => {
      if (!hasRecs) return;
      setError(null);

      const listingsData = {};
      for (const car of recommendations) {
        const modelName = car.vehicleName || car.name;
        const allListings = await fetchCarListings(modelName);
        listingsData[modelName] = allListings;
      }
      setCarListings(listingsData);
    };
    fetchAllListings();
  }, [recommendations, hasRecs, fetchCarListings]);

  // Add to favorites or remove from favorites (toggle)
  const handleFavorite = async (car) => {
    if (!user?.id) {
      alert("Please log in to save favorites.");
      return;
    }

    let accessToken;
    try {
      const request = { ...loginRequest, account: accounts[0] };
      const tokenResponse = await instance.acquireTokenSilent(request);
      accessToken = tokenResponse.accessToken;
    } catch (tokenError) {
      console.error("Error acquiring token:", tokenError);
      alert("Could not acquire an access token. Please try logging in again.");
      return;
    }

    const fullName = car.vehicleName || car.name || "Unknown Vehicle";
    const nameParts = fullName.split(' ');
    const make = nameParts[0];
    const model = nameParts.slice(1).join(' ') || 'Unknown';

    // Price from the first listing
    const modelName = car.vehicleName || car.name;
    const firstListing = carListings[modelName]?.[0];
    const price = firstListing?.priceNumber || 0;

    const username = user.displayName || user.name;
    const email = user.email;
    const imageUrl = (car.images && car.images[0]) ? car.images[0] : '';
    const userResponses = responses;
    const { whyIdeal, keyFeatures, quickSpecs } = car;

    try {
      const response = await fetch('https://carprojfunctionappnew.azurewebsites.net/api/savefavorite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          userId: user.id,
          username,
          email,
          make,
          model,
          price,
          imageUrl,
          userResponses,
          whyIdeal,
          keyFeatures,
          quickSpecs
        })
      });
      if (!response.ok) {
        let errorMsg = 'Failed to update favorite';
        try {
          const errData = await response.json();
          errorMsg = errData.error || errorMsg;
        } catch {}
        throw new Error(errorMsg);
      }

      const result = await response.json();
      // Update local state accordingly
      if (result.message === "Removed from favorites") {
        setFavoritedCars(prev => ({ ...prev, [fullName]: false }));
        alert(`Removed from favorites: ${fullName}`);
      } else {
        setFavoritedCars(prev => ({ ...prev, [fullName]: true }));
        alert(`Added to favorites: ${fullName}`);
      }
    } catch (err) {
      console.error("Error updating favorite:", err.message);
      alert("Error updating favorite. Please try again later.");
    }
  };

  // "Next" / "Prev" navigation handlers
  const handleNext = () => {
    setShowListings(false);
    setCurrentIndex((prev) => {
      const newIndex = Math.min(prev + 1, recommendations.length - 1);
      setCarouselKey((old) => old + 1); // force re-mount for new car
      return newIndex;
    });
  };

  const handlePrev = () => {
    setShowListings(false);
    setCurrentIndex((prev) => {
      const newIndex = Math.max(prev - 1, 0);
      setCarouselKey((old) => old + 1); // force re-mount for new car
      return newIndex;
    });
  };

  // Fallback if no recommendations
  if (!hasRecs) {
    return (
      <div className="recommendations-container">
        <h3>No car recommendations available.</h3>
        <button onClick={onStartOver} className="start-over-button">
          Start Over
        </button>
      </div>
    );
  }

  // The single car we're showing
  const car = recommendations[currentIndex];
  const fullName = car.vehicleName || car.name;
  const isFavorited = favoritedCars[fullName] === true;
  const listings = carListings[fullName] || [];

  // Helper inline function: return the exterior image (assumed to be at index 0)
  const getExteriorImage = (carData) => {
    if (carData.images && carData.images.length > 0) {
      return carData.images[0];
    }
    return null;
  };

  return (
    <div className="recommendations-container single-card-layout">
      {error && <div className="error-message">{error}</div>}

      {car.images && car.images.length > 0 ? (
        <CarImageCarousel images={car.images} key={carouselKey} />
      ) : (
        <div className="car-hero-image">
          <img src="https://placehold.co/600x300?text=No+images" alt="No images available" />
        </div>
      )}

      <div className="single-reco-card">
        <div className="card-header-row">
          <h2 className="card-title">{fullName}</h2>
          <div className="card-header-buttons">
            <button
              className="star-favorite-button"
              onClick={() => handleFavorite(car)}
              title="Add to Favorites"
              style={{
                color: isFavorited ? 'black' : 'white',
                textShadow: isFavorited ? 'none' : '0 0 4px #000'
              }}
            >
              ★
            </button>
            <button
              className="compare-button"
              onClick={() => {
                setComparePopupVisible(true);
                setCompareTargetCar(null);
              }}
              title="Compare Car"
            >
              <img src={compareIcon} alt="Compare Cars" className="compare-icon" />
            </button>
          </div>
        </div>

        {/* Why It’s Ideal */}
        {car.whyIdeal && Array.isArray(car.whyIdeal) && car.whyIdeal.length > 0 && (
          <>
            <h4 className="section-heading">Why It’s Ideal for You</h4>
            <ul className="lookfor-list">
              {car.whyIdeal.map((point, idx) => (
                <li key={idx}>{point}</li>
              ))}
            </ul>
          </>
        )}

        {/* Key Features */}
        {car.keyFeatures && Array.isArray(car.keyFeatures) && car.keyFeatures.length > 0 && (
          <>
            <h4 className="section-heading">Key Features</h4>
            <ul className="lookfor-list">
              {car.keyFeatures.map((feat, idx) => (
                <li key={idx}>{feat}</li>
              ))}
            </ul>
          </>
        )}

        {/* Quick Specs */}
        {car.quickSpecs && (
          <>
            <h4 className="section-heading">Quick Specs</h4>
            <ul className="lookfor-list">
              {car.quickSpecs.priceRange && (
                <li>
                  <strong className="spec-label">Price Range:</strong> {car.quickSpecs.priceRange}
                </li>
              )}
              {car.quickSpecs.seats && (
                <li>
                  <strong className="spec-label">Seats:</strong> {car.quickSpecs.seats}
                </li>
              )}
              {car.quickSpecs.fuelEconomy && (
                <li>
                  <strong className="spec-label">Fuel Economy:</strong> {car.quickSpecs.fuelEconomy}
                </li>
              )}
              {car.quickSpecs.warranty && (
                <li>
                  <strong className="spec-label">Warranty:</strong> {car.quickSpecs.warranty}
                </li>
              )}
            </ul>
          </>
        )}

        {/* Fallback fields */}
        {car.description && (
          <p className="car-description">{car.description}</p>
        )}
        {car.lookFor && (
          <ul className="lookfor-list">
            {car.lookFor.split(';').map((item, idx) => (
              <li key={idx}>{item.trim()}</li>
            ))}
          </ul>
        )}

        {/* Bottom row: Prev/Next/See Listings */}
        <div className="bottom-buttons-row" style={{ justifyContent: 'space-between', width: '100%' }}>
          {currentIndex > 0 && (
            <button
              className="next-button"
              onClick={handlePrev}
              style={{ marginRight: 'auto' }}
            >
              Prev.
            </button>
          )}
          <button
            className="see-listings-button"
            onClick={() => setShowListings(!showListings)}
          >
            {showListings ? 'Hide Listings' : 'See Listings'}
          </button>
          {currentIndex < recommendations.length - 1 && (
            <button
              className="next-button"
              onClick={handleNext}
              style={{ marginLeft: 'auto' }}
            >
              Next
            </button>
          )}
        </div>
      </div>

      {/* Show listings if toggled */}
      {showListings && (
        <div className="listings-section">
          <h4>Nearby {fullName} Listings:</h4>
          {loading ? (
            <p className="loading-text">Finding available cars...</p>
          ) : listings.length > 0 ? (
            listings.map((listing, idx) => (
              <div className="listing-item" key={idx}>
                <h5>{listing.title}</h5>
                <a
                  href={listing.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="view-listing-button"
                >
                  View Listing
                </a>
              </div>
            ))
          ) : (
            <p className="no-listings">No listings found</p>
          )}
        </div>
      )}

      {/* Compare Modal */}
      {comparePopupVisible && (
        <div className="compare-modal-overlay">
          <div className="compare-modal">
            <div className="compare-modal-header">
              <h3>Compare {fullName} with another car</h3>
              <button
                onClick={() => {
                  setComparePopupVisible(false);
                  setCompareTargetCar(null);
                }}
              >
                Close
              </button>
            </div>
            {!compareTargetCar ? (
              <div className="compare-selection">
                <p>Select a car to compare:</p>
                <ul className="compare-selection-list">
                  {recommendations
                    .filter((rec, idx) => idx !== currentIndex)
                    .map((rec, idx) => (
                      <li key={idx} className="compare-select-item">
                        <button
                          onClick={() => setCompareTargetCar(rec)}
                          className="compare-select-button"
                        >
                          <img
                            src={
                              rec.images && rec.images.length > 0
                                ? rec.images[0]
                                : 'https://placehold.co/100x60?text=No+Image'
                            }
                            alt={rec.vehicleName || rec.name}
                            className="compare-select-image"
                          />
                          <span>{rec.vehicleName || rec.name}</span>
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
            ) : (
              <div className="compare-result">
                {/* Display both car exterior images (index 0) above the table */}
                <div className="compare-images">
                  <div className="compare-image-container">
                    <img
                      src={
                        getExteriorImage(car) ||
                        'https://placehold.co/200x100?text=No+Image'
                      }
                      alt={fullName}
                      className="compare-car-image"
                    />
                    <p>{fullName}</p>
                  </div>
                  <div className="compare-image-container">
                    <img
                      src={
                        getExteriorImage(compareTargetCar) ||
                        'https://placehold.co/200x100?text=No+Image'
                      }
                      alt={compareTargetCar.vehicleName || compareTargetCar.name}
                      className="compare-car-image"
                    />
                    <p>{compareTargetCar.vehicleName || compareTargetCar.name}</p>
                  </div>
                </div>

                {/* <h4>Comparison</h4> */}
                <table className="compare-table">
                  <thead>
                    <tr>
                      <th>{fullName}</th>
                      <th>{compareTargetCar.vehicleName || compareTargetCar.name}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{car.quickSpecs?.priceRange || 'N/A'}</td>
                      <td>{compareTargetCar.quickSpecs?.priceRange || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td>{car.quickSpecs?.seats || 'N/A'}</td>
                      <td>{compareTargetCar.quickSpecs?.seats || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td>{car.quickSpecs?.fuelEconomy || 'N/A'}</td>
                      <td>{compareTargetCar.quickSpecs?.fuelEconomy || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td>{car.quickSpecs?.warranty || 'N/A'}</td>
                      <td>{compareTargetCar.quickSpecs?.warranty || 'N/A'}</td>
                    </tr>
                    {/* Add additional rows as needed */}
                  </tbody>
                </table>
                <button onClick={() => setCompareTargetCar(null)}>
                  Choose another car
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      <button onClick={onStartOver} className="start-over-button">
        Start Over
      </button>
    </div>
  );
}

export default RecommendationsDisplay;