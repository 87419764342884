import React from 'react';
import { Link } from 'react-router-dom';
import AskQarby from '../components/AskQarby';
import { HeroiconsOutlineSparkles2 as IconSparkles } from '../icons/HeroiconsOutlineSparkles2';
import msgIcon from '../assets/msg_icon.png';
import view360Icon from '../assets/360-view-1.svg';
import './AskQarbyPage.css';

const AskQarbyPage = () => {
  return (
    <div className="ask-qarby-page">
      {/* Main chat content */}
      <div className="ask-qarby-content">
        <AskQarby />
      </div>

      {/* Disclaimer text */}
      <div className="ask-qarby-disclaimer">
        By using Qarby, you agree to our&nbsp;
        <a href="/terms">Terms</a> &amp; <a href="/privacy">Privacy Policy</a>.
        &nbsp;Qarby does not store your personal data.
      </div>

      {/* Bottom icon row:
          - On mobile (max-width: 768px): fixed at bottom.
          - On larger screens: appears in flow beneath the disclaimer */}
      <div className="ask-qarby-bottom-icons">
        <Link to="/askqarby" className="nav-item">
          <div className="nav-icon-bg">
            <img src={msgIcon} alt="Chat Icon" className="nav-icon" />
          </div>
        </Link>
        <Link to="/findyourcar" className="nav-item">
          <div className="nav-icon-bg">
            <IconSparkles className="nav-icon" />
          </div>
        </Link>
        <Link to="/garage" className="nav-item">
          <div className="nav-icon-bg">
            <img src={view360Icon} alt="Garage Icon" className="nav-icon" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AskQarbyPage;