/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsOutlineSparkles2 = ({ className }) => {
  return (
    <svg
      className={`heroicons-outline-sparkles-2 ${className}`}
      fill="none"
      height="47"
      viewBox="0 0 47 47"
      width="47"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M19.2582 31.0412L17.6849 36.5476L16.1117 31.0412C15.2853 28.149 13.0246 25.8883 10.1324 25.0619L4.62598 23.4887L10.1324 21.9154C13.0246 21.0891 15.2853 18.8283 16.1117 15.9361L17.6849 10.4297L19.2582 15.9361C20.0845 18.8283 22.3453 21.0891 25.2375 21.9154L30.7439 23.4887L25.2375 25.0619C22.3453 25.8883 20.0845 28.149 19.2582 31.0412Z"
        fill="white"
      />

      <path
        className="path"
        d="M35.5977 17.1324L35.0969 19.1357L34.596 17.1324C34.0112 14.793 32.1846 12.9664 29.8452 12.3815L27.8419 11.8807L29.8452 11.3799C32.1846 10.795 34.0112 8.96841 34.596 6.629L35.0969 4.62573L35.5977 6.629C36.1825 8.96841 38.0092 10.795 40.3486 11.3799L42.3518 11.8807L40.3486 12.3815C38.0092 12.9664 36.1825 14.793 35.5977 17.1324Z"
        fill="white"
      />

      <path
        className="path"
        d="M32.9576 40.0634L32.1949 42.3516L31.4321 40.0634C30.9989 38.7636 29.9789 37.7436 28.6791 37.3103L26.3909 36.5476L28.6791 35.7849C29.9789 35.3516 30.9989 34.3316 31.4321 33.0318L32.1949 30.7436L32.9576 33.0318C33.3909 34.3316 34.4108 35.3516 35.7107 35.7849L37.9988 36.5476L35.7107 37.3103C34.4108 37.7436 33.3909 38.7636 32.9576 40.0634Z"
        fill="white"
      />

      <path
        className="path"
        d="M19.2582 31.0412L17.6849 36.5476L16.1117 31.0412C15.2853 28.149 13.0246 25.8883 10.1324 25.0619L4.62598 23.4887L10.1324 21.9154C13.0246 21.0891 15.2853 18.8283 16.1117 15.9361L17.6849 10.4297L19.2582 15.9361C20.0845 18.8283 22.3453 21.0891 25.2375 21.9154L30.7439 23.4887L25.2375 25.0619C22.3453 25.8883 20.0845 28.149 19.2582 31.0412Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />

      <path
        className="path"
        d="M35.5977 17.1324L35.0969 19.1357L34.596 17.1324C34.0112 14.793 32.1846 12.9664 29.8452 12.3815L27.8419 11.8807L29.8452 11.3799C32.1846 10.795 34.0112 8.96841 34.596 6.629L35.0969 4.62573L35.5977 6.629C36.1825 8.96841 38.0092 10.795 40.3486 11.3799L42.3518 11.8807L40.3486 12.3815C38.0092 12.9664 36.1825 14.793 35.5977 17.1324Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />

      <path
        className="path"
        d="M32.9576 40.0634L32.1949 42.3516L31.4321 40.0634C30.9989 38.7636 29.9789 37.7436 28.6791 37.3103L26.3909 36.5476L28.6791 35.7849C29.9789 35.3516 30.9989 34.3316 31.4321 33.0318L32.1949 30.7436L32.9576 33.0318C33.3909 34.3316 34.4108 35.3516 35.7107 35.7849L37.9988 36.5476L35.7107 37.3103C34.4108 37.7436 33.3909 38.7636 32.9576 40.0634Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
