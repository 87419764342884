// src/components/FAQSection.js
import React, { useState } from "react";
import { HeroiconsOutlineArrowUpRight6 } from "../icons/HeroiconsOutlineArrowUpRight6";
import styles from "./FAQSection.module.css";

const FAQSection = () => {
  const faqData = [
    {
      question: "What is Qarby?",
      answer:
        "Qarby is your all-in-one AI car assistant, designed to guide you through every step of your car journey—from researching and buying a vehicle to ongoing maintenance and troubleshooting.",
    },
    {
      question: "How does Qarby work?",
      answer:
        "Qarby uses advanced AI technology to provide you with trusted information, expert insights, and personalized guidance. Simply type your question into the chat, and Qarby will offer real-time answers or connect you with automotive experts and mechanics when needed.",
    },
    {
      question: "What is the purpose of the car chat app?", 
      answer: "The Qarby Car Chat App is your AI-powered assistant for all car-related questions. Whether you're troubleshooting an issue, researching general car information, looking for maintenance advice, or seeking a trusted mechanic, our app provides instant, reliable answers and expert recommendations to support every step of your car journey."
    },
    {
      question: "What types of car-related questions can Qarby answer?",
      answer:
        "Qarby can help with a wide range of topics, including:\nCar buying advice and research\nMaintenance tips and troubleshooting\nPerformance upgrades and modifications\nSafety features and technical specifications\nGeneral car research questions\nAnd more!",
    },
    {
      question: "Can Qarby help me troubleshoot car issues?",
      answer:
        "Absolutely. Qarby is equipped to help diagnose common car problems and provide step-by-step guidance for troubleshooting. If needed, it can also connect you with trusted mechanics for professional assistance.",
    },
    {
      question: "How do I connect with automotive experts through Qarby?",
      answer:
        "If your question requires expert advice, Qarby can recommend local mechanics or automotive professionals. It streamlines the process, so you can get the help you need quickly and efficiently.",
    },
    {
      question: "What are Qarby's operating hours?",
      answer:
        "Qarby is available on weekdays from 9 AM to 5 PM PT. If you have any questions outside these hours, please leave a message (info@qarby.com) and we’ll get back to you during business hours.",
    },    
    {
      question: "How do I join the Qarby community?",
      answer:
        "Joining the Qarby community is easy! Sign up through our app or website to connect with fellow car enthusiasts, share experiences, access exclusive insights, and stay updated on the latest car trends and tips.",
    },
    {
      question: "Is my personal information safe with Qarby?",
      answer:
        "Your privacy and data security are our top priorities. Qarby uses industry-standard security protocols to ensure that your personal information is protected at all times.",
    },
  ];

  const [openFaq, setOpenFaq] = useState(
    faqData.reduce((acc, _, index) => {
      acc[index] = false;
      return acc;
    }, {})
  );

  const toggleFAQ = (index) => {
    setOpenFaq((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <div className={styles.faqContainer}>
      <h2 className={styles.heading}>Frequently Asked</h2>
      {faqData.map((item, idx) => {
        const isOpen = openFaq[idx];
        return (
          <div
            key={idx}
            className={`${styles.faqItem} ${isOpen ? styles.faqItemOpen : ""}`}
            onClick={() => toggleFAQ(idx)}
          >
            <div className={styles.questionRow}>
              <p className={styles.questionText}>{item.question}</p>
              <div className={styles.arrowCircle}>
                <HeroiconsOutlineArrowUpRight6
                  className={`${styles.arrowIcon} ${
                    isOpen ? styles.arrowIconRotated : ""
                  }`}
                />
              </div>
            </div>
            {isOpen && <p className={styles.answer}>{item.answer}</p>}
          </div>
        );
      })}
    </div>
  );
};

export default FAQSection;
