import React from "react";
import CommunityCTA from "../components/CommunityCTA";
import MeetQarby from "../components/MeetQarby";
import FeaturesSection from "../components/FeaturesSection";
import FAQSection from "../components/FAQSection";
import FindYourCarSection from "../components/FindYourCarSection";
import "../styles/LandingPage.css";

const LandingPage = () => {
  return (
    <div className="landing-page">
      <FindYourCarSection />
      <MeetQarby />
      <FeaturesSection />
      <CommunityCTA />
      <FAQSection />
    </div>
  );
};

export default LandingPage;
