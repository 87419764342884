// src/components/CarImageCarousel.jsx
import React, { useState } from 'react';
import './Carousel.css'; // a separate CSS file for carousel styles (optional)

function CarImageCarousel({ images = [], hideArrows = false }) {
  const [currentIdx, setCurrentIdx] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);

  const nextImage = () => {
    setCurrentIdx((prev) => (prev + 1) % images.length);
  };
  const prevImage = () => {
    setCurrentIdx((prev) => (prev - 1 + images.length) % images.length);
  };

  // track swipe start
  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  // check swipe distance
  const handleTouchMove = (e) => {
    if (touchStartX === null) return;
    const diff = touchStartX - e.touches[0].clientX;
    if (diff > 50) {
      // swipe left => next
      nextImage();
      setTouchStartX(null);
    } else if (diff < -50) {
      // swipe right => prev
      prevImage();
      setTouchStartX(null);
    }
  };

  if (!images.length) return null; // no images, no carousel

  return (
    <div
      className="carousel-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <div className="carousel-slide">
        <img src={images[currentIdx]} alt="Car Slide" />
      </div>

      {/* If hideArrows is false, show left/right arrow buttons */}
      {!hideArrows && (
        <>
          <button className="carousel-arrow left" onClick={prevImage}>‹</button>
          <button className="carousel-arrow right" onClick={nextImage}>›</button>
        </>
      )}

      <div className="carousel-indicators">
        {images.map((_, idx) => (
          <span
            key={idx}
            className={`dot ${idx === currentIdx ? 'active' : ''}`}
            onClick={() => setCurrentIdx(idx)}
          />
        ))}
      </div>
    </div>
  );
}

export default CarImageCarousel;
