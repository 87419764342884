import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { useUser } from '../UserContext'; 
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../loginRequest';
import Qarby_Logo from '../assets/Qarby_Logo.png';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { user, setUser } = useUser();
  const { instance } = useMsal();

  // Handle MSAL login
  const handleLogin = async () => {
    try {
      const loginResponse = await instance.loginPopup(loginRequest);
      instance.setActiveAccount(loginResponse.account);
      const account = loginResponse.account;
      const name = account.idTokenClaims?.name || account.username;
      const email = account.idTokenClaims?.email || account.username;
      setUser({
        id: account.localAccountId,
        displayName: name,
        email: email
      });
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  // Handle MSAL logout
  const handleLogout = async () => {
    try {
      await instance.logoutRedirect();
      setUser(null);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  // Toggle mobile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    // Wrap the entire Navbar in a scale-wrapper container
    <div className="scale-wrapper">
      <header className="header">
        {/* Top nav bar area */}
        <div className="nav-top">
          {/* The main row holding logo + text, middle links, and right side */}
          <div className="navbar-row">
            {/* Logo + "qarby" text on the left */}
            <Link to="/" className="brand-container">
              <img
                className="qarby-primary-logo-2"
                src={Qarby_Logo}
                alt="Qarby primary logo"
              />
              <span className="brand-text">qarby</span>
            </Link>
            
            {/* Middle links (About, Community, Garage) */}
            <div className="frame-11">
              <Link className="text-wrapper-30" to="/about">About</Link>
              <Link className="text-wrapper-30" to="/community">Community</Link>
              <Link className="text-wrapper-30" to="/garage">Garage</Link>
            </div>
            
            {/* Right side: Auth controls and hamburger */}
            <div className="frame-12">
              {user && user.id ? (
                <>
                  <span className="welcome-message">
                    Welcome, {user.displayName}!
                  </span>
                  <button className="login-button" onClick={handleLogout}>
                    Logout
                  </button>
                </>
              ) : (
                <button className="login-button" onClick={handleLogin}>
                  Login
                </button>
              )}
              {/* Hamburger icon for mobile */}
              <div className="hamburger" onClick={toggleMenu}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile dropdown */}
        <nav className={`navbar-links-container ${menuOpen ? 'open' : ''}`}>
          <ul className="navbar-links">
            <li>
              <Link to="/" onClick={() => setMenuOpen(false)}>Home</Link>
            </li>
            <li>
              <Link to="/about" onClick={() => setMenuOpen(false)}>About</Link>
            </li>
            <li>
              <Link to="/ai" onClick={() => setMenuOpen(false)}>Ask Qarby</Link>
            </li>
            <li>
              <Link to="/resources" onClick={() => setMenuOpen(false)}>Resources</Link>
            </li>
            <li>
              <Link to="/favorites" onClick={() => setMenuOpen(false)}>Favorites</Link>
            </li>
            <li>
              <Link to="/dealerships" onClick={() => setMenuOpen(false)}>
                Find Dealerships
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;


// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import './Navbar.css';
// import { useUser } from '../UserContext'; 
// import { useMsal } from '@azure/msal-react';
// import { loginRequest } from '../loginRequest';

// // Example logo file import
// import Qarby_Logo from '../assets/Qarby_Logo.png';

// const Navbar = () => {
//   const [menuOpen, setMenuOpen] = useState(false);
//   const { user, setUser } = useUser();
//   const { instance } = useMsal();

//   // Handle MSAL login
//   const handleLogin = async () => {
//     try {
//       const loginResponse = await instance.loginPopup(loginRequest);
//       instance.setActiveAccount(loginResponse.account);
//       const account = loginResponse.account;
//       const name = account.idTokenClaims?.name || account.username;
//       const email = account.idTokenClaims?.email || account.username;

//       setUser({
//         id: account.localAccountId,
//         displayName: name,
//         email: email
//       });
//     } catch (error) {
//       console.error('Login error:', error);
//     }
//   };

//   // Handle MSAL logout
//   const handleLogout = async () => {
//     try {
//       await instance.logoutRedirect();
//       setUser(null);
//     } catch (error) {
//       console.error('Logout error:', error);
//     }
//   };

//   // Toggle mobile menu
//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   return (
//     <header className="header">
//       {/* Top nav bar area */}
//       <div className="nav-top">
//         {/* The main row holding logo + text, middle links, and right side */}
//         <div className="navbar-row">
          
//           {/* Logo + "qarby" text on the left */}
//           <Link to="/" className="brand-container">
//             <img
//               className="qarby-primary-logo-2"
//               src={Qarby_Logo}
//               alt="Qarby primary logo"
//             />
//             <span className="brand-text">qarby</span>
//           </Link>
          
//           {/* Middle links (About, Community, Garage) */}
//           <div className="frame-11">
//             <Link className="text-wrapper-30" to="/about">About</Link>
//             <Link className="text-wrapper-30" to="/community">Community</Link>
//             <Link className="text-wrapper-30" to="/garage">Garage</Link>
//           </div>
          
//           {/* Right side: "Use Qarby", "Get Started," plus login/logout and hamburger */}
//           <div className="frame-12">
//             {/* <div className="text-wrapper-31">Use Qarby</div> */}
//             {/* <div className="frame-13">
//               <Link className="text-wrapper-2" to="/AskOrBuy">
//                 Get Started
//               </Link>
//             </div> */}
            
//             {/* Auth controls */}
//             {user && user.id ? (
//               <>
//                 <span className="welcome-message">
//                   Welcome, {user.displayName}!
//                 </span>
//                 <button className="login-button" onClick={handleLogout}>
//                   Logout
//                 </button>
//               </>
//             ) : (
//               <button className="login-button" onClick={handleLogin}>
//                 Login
//               </button>
//             )}
            
//             {/* Hamburger icon for mobile */}
//             <div className="hamburger" onClick={toggleMenu}>
//               <span className="bar"></span>
//               <span className="bar"></span>
//               <span className="bar"></span>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Mobile dropdown */}
//       <nav className={`navbar-links-container ${menuOpen ? 'open' : ''}`}>
//         <ul className="navbar-links">
//           <li>
//             <Link to="/" onClick={() => setMenuOpen(false)}>Home</Link>
//           </li>
//           <li>
//             <Link to="/about" onClick={() => setMenuOpen(false)}>About</Link>
//           </li>
//           <li>
//             <Link to="/ai" onClick={() => setMenuOpen(false)}>Ask Qarby</Link>
//           </li>
//           <li>
//             <Link to="/resources" onClick={() => setMenuOpen(false)}>Resources</Link>
//           </li>
//           <li>
//             <Link to="/favorites" onClick={() => setMenuOpen(false)}>Favorites</Link>
//           </li>
//           <li>
//             <Link to="/dealerships" onClick={() => setMenuOpen(false)}>
//               Find Dealerships
//             </Link>
//           </li>
//         </ul>
//       </nav>
//     </header>
//   );
// };

// export default Navbar;