import React, { useState, useEffect } from 'react';
import './FindYourCar.css';
import RecommendationsDisplay from './RecommendationsDisplay';
import carImg from '../assets/car.png'; // Make sure you have a car image at this path

// The big brand list
const brandList = [
  'Acura', 'AFEELA', 'Alfa Romeo', 'Aston Martin', 'Audi', 'Bentley', 'BMW', 'Buick',
  'Cadillac', 'Chevrolet', 'Chrysler', 'Daewoo', 'Daihatsu', 'Dodge', 'Eagle', 'Ferrari',
  'FIAT', 'Fisker', 'Ford', 'Freightliner', 'Genesis', 'Geo', 'GMC', 'Honda', 'HUMMER',
  'Hyundai', 'INEOS', 'INFINITI', 'Isuzu', 'Jaguar', 'Jeep', 'Kia', 'Lamborghini',
  'Land Rover', 'Lexus', 'Lincoln', 'Lotus', 'Lucid', 'Maserati', 'Maybach', 'MAZDA',
  'McLaren', 'Mercedes-Benz', 'Mercury', 'MINI', 'Mitsubishi', 'Nissan', 'Oldsmobile',
  'Panoz', 'Plymouth', 'Polestar', 'Pontiac', 'Porsche', 'Ram', 'Rivian', 'Rolls-Royce',
  'Saab', 'Saturn', 'Scion', 'Scout', 'smart', 'SRT', 'Subaru', 'Suzuki', 'Tesla', 'Toyota',
  'VinFast', 'Volkswagen', 'Volvo'
];

const seatOptions = [
  '2 Seats',
  '3-5 Seats',
  '6-8 Seats',
  '8+ Seats'
];

const bodyTypeOptions = [
  'Sedan',
  'Coupe',
  'SUV',
  'Truck',
  'Minivan',
  'Convertible',
  'Hatchback'
];

const powerTypeOptions = [
  'Gas/Diesel (traditional fuel)',
  'Electric (modern, battery-powered)',
  'Hybrid (the best of both worlds)',
  'I want to see all options'
];

const budgetRanges = [
  '< $10k',
  '$10k - $20k',
  '$20k - $30k',
  '$30k - $40k',
  '$40k - $50k',
  '>$50k'
];

// LoadingScreen Component with header text and animation
const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="loading-header">
        Car Buying Starts with <span className="blue-text">Qarby</span>
        <div className="sub-header">
          Finding the Cars that meet Your needs best
          <span className="bubbling-dots">
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </span>
        </div>
      </div>
      <div className="loading-bar">
        <div className="loading-fill"></div>
      </div>
      <div className="car-container">
        <img src={carImg} alt="Car moving" className="loading-car" />
      </div>
    </div>
  );
};

const FindYourCar = ({ onBack }) => {
  const [step, setStep] = useState(1);

  // Main state to store user responses
  const [responses, setResponses] = useState({
    usage: [],
    brand: [],
    mustHaves: [],
    seats: '',
    bodyType: '',
    powerType: '',
    budgetMin: 0,
    budgetMax: 0,
  });

  // When chatResponse is non-empty, the steps are hidden and results are shown
  const [chatResponse, setChatResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // If seat selection changes and invalidates the current bodyType, clear it.
  useEffect(() => {
    if (responses.seats === '6-8 Seats' && responses.bodyType === 'Coupe') {
      setResponses(prev => ({ ...prev, bodyType: '' }));
    } else if (
      responses.seats === '8+ Seats' &&
      (responses.bodyType === 'Coupe' || responses.bodyType === 'Sedan')
    ) {
      setResponses(prev => ({ ...prev, bodyType: '' }));
    }
  }, [responses.seats, responses.bodyType]);

  // Step validation: Step 1 and Step 7 are required.
  const validateStep = (currentStep) => {
    switch (currentStep) {
      case 1:
        return responses.usage.length > 0;
      case 7:
        return responses.budgetMax > 0;
      default:
        return true;
    }
  };

  const nextStep = () => {
    if (!validateStep(step)) {
      alert('Please fill out the required field(s) before proceeding.');
      return;
    }
    setStep(prev => prev + 1);
  };

  const prevStep = () => setStep(prev => prev - 1);

  // Handle brand selection (up to 3)
  const handleBrandChange = (value) => {
    setResponses(prev => {
      const selectedBrands = [...prev.brand];
      if (selectedBrands.includes(value)) {
        return { ...prev, brand: selectedBrands.filter(b => b !== value) };
      } else {
        if (selectedBrands.length < 3) {
          selectedBrands.push(value);
          return { ...prev, brand: selectedBrands };
        } else {
          alert('You can select up to 3 brands only.');
          return prev;
        }
      }
    });
  };

  // Handle checkbox changes for usage and must-haves
  const handleCheckbox = (name, value) => {
    setResponses(prev => {
      const arr = [...prev[name]];
      if (arr.includes(value)) {
        return { ...prev, [name]: arr.filter(item => item !== value) };
      } else {
        arr.push(value);
        return { ...prev, [name]: arr };
      }
    });
  };

  // Handle radio selection; for step 7 also update budget values
  const handleRadio = (stepName, value) => {
    if (step === 7) {
      let min = 0, max = 0;
      switch (value) {
        case '< $10k':
          min = 0;
          max = 10000;
          break;
        case '$10k - $20k':
          min = 10000;
          max = 20000;
          break;
        case '$20k - $30k':
          min = 20000;
          max = 30000;
          break;
        case '$30k - $40k':
          min = 30000;
          max = 40000;
          break;
        case '$40k - $50k':
          min = 40000;
          max = 50000;
          break;
        case '>$50k':
          min = 50000;
          max = 999999;
          break;
        default:
          break;
      }
      setResponses(prev => ({
        ...prev,
        budgetMin: min,
        budgetMax: max
      }));
    }
    setResponses(prev => ({
      ...prev,
      [stepName]: value
    }));
  };

  // Submit and fetch recommendations
  const handleSubmit = async () => {
    if (!validateStep(step)) {
      alert('Please fill all required fields before submitting.');
      return;
    }
    setIsLoading(true);

    try {
      // Prepare values for the API call.
      const usageText = responses.usage.join(', ');
      const brandText = responses.brand.join(', ') || 'No particular preference';
      const mustHavesText = responses.mustHaves.length
        ? responses.mustHaves.join(', ')
        : 'No particular must-haves';

      const functionResponse = await fetch(
        'https://carprojfunctionappnew.azurewebsites.net/api/generatecarrecommendations',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            usageText,
            brandText,
            mustHavesText,
            seats: responses.seats || 'No specific requirement',
            bodyType: responses.bodyType || 'No specific requirement',
            powerType: responses.powerType || 'No specific requirement',
            budgetMin: responses.budgetMin,
            budgetMax: responses.budgetMax,
          }),
        }
      );

      if (!functionResponse.ok) {
        throw new Error(`Azure Function error: ${functionResponse.status}`);
      }

      const finalCars = await functionResponse.json();

      // Example local scoring (adjust as needed)
      const scoredRecommendations = finalCars.map((car) => {
        let score = 0;
        if (responses.brand.length) {
          for (const brand of responses.brand) {
            if (car.name.toLowerCase().includes(brand.toLowerCase())) {
              score += 20;
            }
          }
        }
        if (
          responses.mustHaves.includes('All-wheel drive') &&
          (
            car.description?.toLowerCase().includes('all-wheel') ||
            car.lookFor?.toLowerCase().includes('all-wheel')
          )
        ) {
          score += 10;
        }
        return { ...car, score };
      });

      scoredRecommendations.sort((a, b) => b.score - a.score);
      const finalSorted = scoredRecommendations.slice(0, 7);

      // Set the recommendations to show results.
      setChatResponse(finalSorted);
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      alert('There was an error fetching recommendations. Please try again later.');
      setChatResponse([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Start Over: clear results and reset step.
  const handleStartOver = () => {
    setChatResponse([]);
    setStep(1);
  };

  // When loading, show the custom loading screen.
  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="find-car-layout">
      {chatResponse.length === 0 && (
        <>
          {/* Top row: step indicator, progress bar, and Skip button */}
          <div className="top-row">
            <div className="step-line">
              <span className="step-text">{step}/7</span>
              <div className="progress-container">
                <div
                  className="progress-fill"
                  style={{ width: `${(step / 7) * 100}%` }}
                />
              </div>
              {step < 7 && (
                <button className="skip-btn" onClick={() => setStep(step + 1)}>
                  Skip
                </button>
              )}
            </div>
          </div>

          <div className="question-card">
            {/* Step 1: Car Usage */}
            {step === 1 && (
              <>
                <h2>Step 1 of 7</h2>
                <h3>What do you have in mind for this car?</h3>
                <p className="step-subtitle">
                  Select all that apply (<span className="required">required</span>):
                </p>
                {/* Here we use "equal-width" so that the longest text determines the width */}
                <div className="option-list equal-width">
                  <label className="option-tile">
                    <input
                      type="checkbox"
                      checked={responses.usage.includes('City driving (short trips, urban traffic)')}
                      onChange={() =>
                        handleCheckbox('usage', 'City driving (short trips, urban traffic)')
                      }
                    />
                    <span>City driving (short trips, urban traffic)</span>
                  </label>
                  <label className="option-tile">
                    <input
                      type="checkbox"
                      checked={responses.usage.includes('Long-distance trips (highway, road trips)')}
                      onChange={() =>
                        handleCheckbox('usage', 'Long-distance trips (highway, road trips)')
                      }
                    />
                    <span>Long-distance trips (highway, road trips)</span>
                  </label>
                  <label className="option-tile">
                    <input
                      type="checkbox"
                      checked={responses.usage.includes('Recreational/pleasure driving (sporty, fun drives)')}
                      onChange={() =>
                        handleCheckbox('usage', 'Recreational/pleasure driving (sporty, fun drives)')
                      }
                    />
                    <span>Recreational/pleasure driving (sporty, fun drives)</span>
                  </label>
                  <label className="option-tile">
                    <input
                      type="checkbox"
                      checked={responses.usage.includes('Ridesharing (passenger transport)')}
                      onChange={() =>
                        handleCheckbox('usage', 'Ridesharing (passenger transport)')
                      }
                    />
                    <span>Ridesharing (passenger transport)</span>
                  </label>
                  <label className="option-tile">
                    <input
                      type="checkbox"
                      checked={responses.usage.includes('Outdoor adventures (camping, fishing gear)')}
                      onChange={() =>
                        handleCheckbox('usage', 'Outdoor adventures (camping, fishing gear)')
                      }
                    />
                    <span>Outdoor adventures (camping, fishing gear)</span>
                  </label>
                  <label className="option-tile">
                    <input
                      type="checkbox"
                      checked={responses.usage.includes('Hauling bulky items (extra cargo space)')}
                      onChange={() =>
                        handleCheckbox('usage', 'Hauling bulky items (extra cargo space)')
                      }
                    />
                    <span>Hauling bulky items (extra cargo space)</span>
                  </label>
                  <label className="option-tile">
                    <input
                      type="checkbox"
                      checked={responses.usage.includes('Off-road or snowy conditions (rugged, winter-ready)')}
                      onChange={() =>
                        handleCheckbox('usage', 'Off-road or snowy conditions (rugged, winter-ready)')
                      }
                    />
                    <span>Off-road or snowy conditions (rugged, winter-ready)</span>
                  </label>
                </div>
                <div className="nav-row">
                  <div />
                  <button className="next-btn" onClick={nextStep}>
                    Next
                  </button>
                </div>
              </>
            )}

            {/* Step 2: Car Brand Selection */}
            {step === 2 && (
              <>
                <h2>Step 2 of 7</h2>
                <h3>Which car brand(s) do you like?</h3>
                <p className="step-subtitle">
                  Select up to 3 (<span className="optional">optional</span>):
                </p>
                <div className="option-list wide-grid brand-scroller">
                  {brandList.map(brand => (
                    <label key={brand} className="option-tile">
                      <input
                        type="checkbox"
                        checked={responses.brand.includes(brand)}
                        onChange={() => handleBrandChange(brand)}
                      />
                      <span>{brand}</span>
                    </label>
                  ))}
                </div>
                <div className="nav-row">
                  <button className="back-btn" onClick={prevStep}>
                    Back
                  </button>
                  <button className="next-btn" onClick={nextStep}>
                    Next
                  </button>
                </div>
              </>
            )}

            {/* Step 3: Must-Have Features */}
            {step === 3 && (
              <>
                <h2>Step 3 of 7</h2>
                <h3>What are your must-have features?</h3>
                <p className="step-subtitle">
                  Select all that apply (<span className="optional">optional</span>):
                </p>
                {/* Using "equal-width" so that the longest text sets the bubble width */}
                <div className="option-list equal-width">
                  <label className="option-tile">
                    <input
                      type="checkbox"
                      checked={responses.mustHaves.includes('Wi-Fi connectivity (stream on the go)')}
                      onChange={() =>
                        handleCheckbox('mustHaves', 'Wi-Fi connectivity (stream on the go)')
                      }
                    />
                    <span>Wi-Fi connectivity (stream on the go)</span>
                  </label>
                  <label className="option-tile">
                    <input
                      type="checkbox"
                      checked={responses.mustHaves.includes('Sunroof/Moonroof (slide open the roof)')}
                      onChange={() =>
                        handleCheckbox('mustHaves', 'Sunroof/Moonroof (slide open the roof)')
                      }
                    />
                    <span>Sunroof/Moonroof (slide open the roof)</span>
                  </label>
                  <label className="option-tile">
                    <input
                      type="checkbox"
                      checked={responses.mustHaves.includes('Leather seats (ride in style)')}
                      onChange={() =>
                        handleCheckbox('mustHaves', 'Leather seats (ride in style)')
                      }
                    />
                    <span>Leather seats (ride in style)</span>
                  </label>
                  <label className="option-tile">
                    <input
                      type="checkbox"
                      checked={responses.mustHaves.includes('All-wheel drive (better road traction) (extra grip on any road)')}
                      onChange={() =>
                        handleCheckbox('mustHaves', 'All-wheel drive (better road traction) (extra grip on any road)')
                      }
                    />
                    <span>All-wheel drive (better road traction) (extra grip on any road)</span>
                  </label>
                  <label className="option-tile">
                    <input
                      type="checkbox"
                      checked={responses.mustHaves.includes('Apple CarPlay/Android Auto (smartphone integration)')}
                      onChange={() =>
                        handleCheckbox('mustHaves', 'Apple CarPlay/Android Auto (smartphone integration)')
                      }
                    />
                    <span>Apple CarPlay/Android Auto (smartphone integration)</span>
                  </label>
                  <label className="option-tile">
                    <input
                      type="checkbox"
                      checked={responses.mustHaves.includes('Heated Seats (for comfort in cold weather)')}
                      onChange={() =>
                        handleCheckbox('mustHaves', 'Heated Seats (for comfort in cold weather)')
                      }
                    />
                    <span>Heated Seats (for comfort in cold weather)</span>
                  </label>
                  <label className="option-tile">
                    <input
                      type="checkbox"
                      checked={responses.mustHaves.includes('Blind Spot Monitoring (enhanced safety)')}
                      onChange={() =>
                        handleCheckbox('mustHaves', 'Blind Spot Monitoring (enhanced safety)')
                      }
                    />
                    <span>Blind Spot Monitoring (enhanced safety)</span>
                  </label>
                </div>
                <div className="nav-row">
                  <button className="back-btn" onClick={prevStep}>
                    Back
                  </button>
                  <button className="next-btn" onClick={nextStep}>
                    Next
                  </button>
                </div>
              </>
            )}

            {/* Step 4: Seat Count */}
            {step === 4 && (
              <>
                <h2>Step 4 of 7</h2>
                <h3>How many total seats do you need?</h3>
                <p className="step-subtitle">
                  Select one (<span className="optional">optional</span>):
                </p>
                <div className="option-list narrow-list">
                  {seatOptions.map(seatOpt => (
                    <label key={seatOpt} className="option-tile">
                      <input
                        type="radio"
                        name="seats"
                        checked={responses.seats === seatOpt}
                        onChange={() => setResponses(prev => ({ ...prev, seats: seatOpt }))}
                      />
                      <span>{seatOpt}</span>
                    </label>
                  ))}
                </div>
                <div className="nav-row">
                  <button className="back-btn" onClick={prevStep}>
                    Back
                  </button>
                  <button className="next-btn" onClick={nextStep}>
                    Next
                  </button>
                </div>
              </>
            )}

            {/* Step 5: Preferred Vehicle Type */}
            {step === 5 && (
              <>
                <h2>Step 5 of 7</h2>
                <h3>Preferred type of vehicle?</h3>
                <p className="step-subtitle">
                  Select one (<span className="optional">optional</span>):
                </p>
                <div className="option-list narrow-list">
                  {(
                    responses.seats === '8+ Seats'
                      ? bodyTypeOptions.filter(bt => bt !== 'Coupe' && bt !== 'Sedan')
                      : responses.seats === '6-8 Seats'
                        ? bodyTypeOptions.filter(bt => bt !== 'Coupe')
                        : bodyTypeOptions
                  ).map(bt => (
                    <label key={bt} className="option-tile">
                      <input
                        type="radio"
                        name="bodyType"
                        checked={responses.bodyType === bt}
                        onChange={() => setResponses(prev => ({ ...prev, bodyType: bt }))}
                      />
                      <span>{bt}</span>
                    </label>
                  ))}
                </div>
                <div className="nav-row">
                  <button className="back-btn" onClick={prevStep}>
                    Back
                  </button>
                  <button className="next-btn" onClick={nextStep}>
                    Next
                  </button>
                </div>
              </>
            )}

            {/* Step 6: Power Type */}
            {step === 6 && (
              <>
                <h2>Step 6 of 7</h2>
                <h3>Select your car's energy type</h3>
                <p className="step-subtitle">We have options that are both traditional and modern</p>
                <div className="option-list narrow-list">
                  {powerTypeOptions.map(pt => (
                    <label key={pt} className="option-tile">
                      <input
                        type="radio"
                        name="powerType"
                        checked={responses.powerType === pt}
                        onChange={() => setResponses(prev => ({ ...prev, powerType: pt }))}
                      />
                      <span>{pt}</span>
                    </label>
                  ))}
                </div>
                <div className="nav-row">
                  <button className="back-btn" onClick={prevStep}>
                    Back
                  </button>
                  <button className="next-btn" onClick={nextStep}>
                    Next
                  </button>
                </div>
              </>
            )}

            {/* Step 7: Budget Range */}
            {step === 7 && (
              <>
                <h2>Step 7 of 7</h2>
                <h3>What’s your budget range?</h3>
                <p className="step-subtitle">
                  <span className="required">Required</span>:
                </p>
                <div className="option-list narrow-list">
                  {budgetRanges.map(br => (
                    <label key={br} className="option-tile">
                      <input
                        type="radio"
                        name="budgetRange"
                        onChange={() => handleRadio('budgetRange', br)}
                        checked={
                          (br === '< $10k' && responses.budgetMax === 10000) ||
                          (br === '$10k - $20k' && responses.budgetMin === 10000 && responses.budgetMax === 20000) ||
                          (br === '$20k - $30k' && responses.budgetMin === 20000 && responses.budgetMax === 30000) ||
                          (br === '$30k - $40k' && responses.budgetMin === 30000 && responses.budgetMax === 40000) ||
                          (br === '$40k - $50k' && responses.budgetMin === 40000 && responses.budgetMax === 50000) ||
                          (br === '>$50k' && responses.budgetMin === 50000)
                        }
                      />
                      <span>{br}</span>
                    </label>
                  ))}
                </div>
                <div className="nav-row">
                  <button className="back-btn" onClick={prevStep}>
                    Back
                  </button>
                  <button
                    className="next-btn"
                    onClick={handleSubmit}
                    disabled={isLoading || responses.budgetMax === 0}
                  >
                    {isLoading ? 'Loading...' : 'Submit'}
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      )}

      {chatResponse.length > 0 && (
        <RecommendationsDisplay
          recommendations={chatResponse}
          responses={responses}
          onStartOver={handleStartOver}
        />
      )}

      <footer className="disclaimer-area">
        By using Qarby, you agree to our <a href="/terms">Terms</a> and <a href="/privacy">Privacy Policy</a>. Qarby was built to answer questions related to car purchasing, leasing and maintenance.
      </footer>
    </div>
  );
};

export default FindYourCar;


// import React, { useState, useEffect } from 'react';
// import './FindYourCar.css';
// import RecommendationsDisplay from './RecommendationsDisplay';
// import carImg from '../assets/car.png'; // Make sure you have a car image at this path

// // The big brand list
// const brandList = [
//   'Acura', 'AFEELA', 'Alfa Romeo', 'Aston Martin', 'Audi', 'Bentley', 'BMW', 'Buick',
//   'Cadillac', 'Chevrolet', 'Chrysler', 'Daewoo', 'Daihatsu', 'Dodge', 'Eagle', 'Ferrari',
//   'FIAT', 'Fisker', 'Ford', 'Freightliner', 'Genesis', 'Geo', 'GMC', 'Honda', 'HUMMER',
//   'Hyundai', 'INEOS', 'INFINITI', 'Isuzu', 'Jaguar', 'Jeep', 'Kia', 'Lamborghini',
//   'Land Rover', 'Lexus', 'Lincoln', 'Lotus', 'Lucid', 'Maserati', 'Maybach', 'MAZDA',
//   'McLaren', 'Mercedes-Benz', 'Mercury', 'MINI', 'Mitsubishi', 'Nissan', 'Oldsmobile',
//   'Panoz', 'Plymouth', 'Polestar', 'Pontiac', 'Porsche', 'Ram', 'Rivian', 'Rolls-Royce',
//   'Saab', 'Saturn', 'Scion', 'Scout', 'smart', 'SRT', 'Subaru', 'Suzuki', 'Tesla', 'Toyota',
//   'VinFast', 'Volkswagen', 'Volvo'
// ];

// const seatOptions = [
//   '2 Seats',
//   '3-5 Seats',
//   '6-8 Seats',
//   '8+ Seats'
// ];

// const bodyTypeOptions = [
//   'Sedan',
//   'Coupe',
//   'SUV',
//   'Truck',
//   'Minivan',
//   'Convertible',
//   'Hatchback'
// ];

// const powerTypeOptions = [
//   'Gas/Diesel (traditional fuel)',
//   'Electric (modern, battery-powered)',
//   'Hybrid (the best of both worlds)',
//   'I want to see all options'
// ];

// const budgetRanges = [
//   '< $10k',
//   '$10k - $20k',
//   '$20k - $30k',
//   '$30k - $40k',
//   '$40k - $50k',
//   '>$50k'
// ];

// // LoadingScreen Component with header text and animation
// const LoadingScreen = () => {
//   return (
//     <div className="loading-screen">
//       <div className="loading-header">
//         Car Buying Starts with <span className="blue-text">Qarby</span>
//         <div className="sub-header">
//           Finding the Cars that meet Your needs best
//           <span className="bubbling-dots">
//             <span className="dot">.</span>
//             <span className="dot">.</span>
//             <span className="dot">.</span>
//           </span>
//         </div>
//       </div>
//       <div className="loading-bar">
//         <div className="loading-fill"></div>
//       </div>
//       <div className="car-container">
//         <img src={carImg} alt="Car moving" className="loading-car" />
//       </div>
//     </div>
//   );
// };

// const FindYourCar = ({ onBack }) => {
//   const [step, setStep] = useState(1);

//   // Main state to store user responses
//   const [responses, setResponses] = useState({
//     usage: [],
//     brand: [],
//     mustHaves: [],
//     seats: '',
//     bodyType: '',
//     powerType: '',
//     budgetMin: 0,
//     budgetMax: 0,
//   });

//   // When chatResponse is non-empty, the steps are hidden and results are shown
//   const [chatResponse, setChatResponse] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   // If seat selection changes and invalidates the current bodyType, clear it.
//   useEffect(() => {
//     if (responses.seats === '6-8 Seats' && responses.bodyType === 'Coupe') {
//       setResponses(prev => ({ ...prev, bodyType: '' }));
//     } else if (
//       responses.seats === '8+ Seats' &&
//       (responses.bodyType === 'Coupe' || responses.bodyType === 'Sedan')
//     ) {
//       setResponses(prev => ({ ...prev, bodyType: '' }));
//     }
//   }, [responses.seats, responses.bodyType]);

//   // Step validation: Step 1 and Step 7 are required.
//   const validateStep = (currentStep) => {
//     switch (currentStep) {
//       case 1:
//         return responses.usage.length > 0;
//       case 7:
//         return responses.budgetMax > 0;
//       default:
//         return true;
//     }
//   };

//   const nextStep = () => {
//     if (!validateStep(step)) {
//       alert('Please fill out the required field(s) before proceeding.');
//       return;
//     }
//     setStep(prev => prev + 1);
//   };

//   const prevStep = () => setStep(prev => prev - 1);

//   // Handle brand selection (up to 3)
//   const handleBrandChange = (value) => {
//     setResponses(prev => {
//       const selectedBrands = [...prev.brand];
//       if (selectedBrands.includes(value)) {
//         return { ...prev, brand: selectedBrands.filter(b => b !== value) };
//       } else {
//         if (selectedBrands.length < 3) {
//           selectedBrands.push(value);
//           return { ...prev, brand: selectedBrands };
//         } else {
//           alert('You can select up to 3 brands only.');
//           return prev;
//         }
//       }
//     });
//   };

//   // Handle checkbox changes for usage and must-haves
//   const handleCheckbox = (name, value) => {
//     setResponses(prev => {
//       const arr = [...prev[name]];
//       if (arr.includes(value)) {
//         return { ...prev, [name]: arr.filter(item => item !== value) };
//       } else {
//         arr.push(value);
//         return { ...prev, [name]: arr };
//       }
//     });
//   };

//   // Handle radio selection; for step 7 also update budget values
//   const handleRadio = (stepName, value) => {
//     if (step === 7) {
//       let min = 0, max = 0;
//       switch (value) {
//         case '< $10k':
//           min = 0;
//           max = 10000;
//           break;
//         case '$10k - $20k':
//           min = 10000;
//           max = 20000;
//           break;
//         case '$20k - $30k':
//           min = 20000;
//           max = 30000;
//           break;
//         case '$30k - $40k':
//           min = 30000;
//           max = 40000;
//           break;
//         case '$40k - $50k':
//           min = 40000;
//           max = 50000;
//           break;
//         case '>$50k':
//           min = 50000;
//           max = 999999;
//           break;
//         default:
//           break;
//       }
//       setResponses(prev => ({
//         ...prev,
//         budgetMin: min,
//         budgetMax: max
//       }));
//     }
//     setResponses(prev => ({
//       ...prev,
//       [stepName]: value
//     }));
//   };

//   // Submit and fetch recommendations
//   const handleSubmit = async () => {
//     if (!validateStep(step)) {
//       alert('Please fill all required fields before submitting.');
//       return;
//     }
//     setIsLoading(true);

//     try {
//       // Prepare values for the API call.
//       const usageText = responses.usage.join(', ');
//       const brandText = responses.brand.join(', ') || 'No particular preference';
//       const mustHavesText = responses.mustHaves.length
//         ? responses.mustHaves.join(', ')
//         : 'No particular must-haves';

//       const functionResponse = await fetch(
//         'https://carprojfunctionappnew.azurewebsites.net/api/generatecarrecommendations',
//         {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json' },
//           body: JSON.stringify({
//             usageText,
//             brandText,
//             mustHavesText,
//             seats: responses.seats || 'No specific requirement',
//             bodyType: responses.bodyType || 'No specific requirement',
//             powerType: responses.powerType || 'No specific requirement',
//             budgetMin: responses.budgetMin,
//             budgetMax: responses.budgetMax,
//           }),
//         }
//       );

//       if (!functionResponse.ok) {
//         throw new Error(`Azure Function error: ${functionResponse.status}`);
//       }

//       const finalCars = await functionResponse.json();

//       // Example local scoring (adjust as needed)
//       const scoredRecommendations = finalCars.map((car) => {
//         let score = 0;
//         if (responses.brand.length) {
//           for (const brand of responses.brand) {
//             if (car.name.toLowerCase().includes(brand.toLowerCase())) {
//               score += 20;
//             }
//           }
//         }
//         if (
//           responses.mustHaves.includes('All-wheel drive') &&
//           (
//             car.description?.toLowerCase().includes('all-wheel') ||
//             car.lookFor?.toLowerCase().includes('all-wheel')
//           )
//         ) {
//           score += 10;
//         }
//         return { ...car, score };
//       });

//       scoredRecommendations.sort((a, b) => b.score - a.score);
//       const finalSorted = scoredRecommendations.slice(0, 7);

//       // Set the recommendations to show results.
//       setChatResponse(finalSorted);
//     } catch (error) {
//       console.error('Error fetching recommendations:', error);
//       alert('There was an error fetching recommendations. Please try again later.');
//       setChatResponse([]);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   // Start Over: clear results and reset step.
//   const handleStartOver = () => {
//     setChatResponse([]);
//     setStep(1);
//   };

//   // When loading, show the custom loading screen.
//   if (isLoading) {
//     return <LoadingScreen />;
//   }

//   return (
//     <div className="find-car-layout">
//       {chatResponse.length === 0 && (
//         <>
//           {/* Top row: step indicator, progress bar, and Skip button */}
//           <div className="top-row">
//             <div className="step-line">
//               <span className="step-text">{step}/7</span>
//               <div className="progress-container">
//                 <div
//                   className="progress-fill"
//                   style={{ width: `${(step / 7) * 100}%` }}
//                 />
//               </div>
//               {step < 7 && (
//                 <button className="skip-btn" onClick={() => setStep(step + 1)}>
//                   Skip
//                 </button>
//               )}

//             </div>
//           </div>

//           <div className="question-card">
//             {/* Step 1: Car Usage */}
//             {step === 1 && (
//               <>
//                 <h2>Step 1 of 7</h2>
//                 <h3>What do you have in mind for this car?</h3>
//                 <p className="step-subtitle">Select all that apply (required):</p>
//                 {/* Here we use "equal-width" so that the longest text determines the width */}
//                 <div className="option-list equal-width">
//                   <label className="option-tile">
//                     <input
//                       type="checkbox"
//                       checked={responses.usage.includes('City driving (short trips, urban traffic)')}
//                       onChange={() =>
//                         handleCheckbox('usage', 'City driving (short trips, urban traffic)')
//                       }
//                     />
//                     <span>City driving (short trips, urban traffic)</span>
//                   </label>
//                   <label className="option-tile">
//                     <input
//                       type="checkbox"
//                       checked={responses.usage.includes('Long-distance trips (highway, road trips)')}
//                       onChange={() =>
//                         handleCheckbox('usage', 'Long-distance trips (highway, road trips)')
//                       }
//                     />
//                     <span>Long-distance trips (highway, road trips)</span>
//                   </label>
//                   <label className="option-tile">
//                     <input
//                       type="checkbox"
//                       checked={responses.usage.includes('Recreational/pleasure driving (sporty, fun drives)')}
//                       onChange={() =>
//                         handleCheckbox('usage', 'Recreational/pleasure driving (sporty, fun drives)')
//                       }
//                     />
//                     <span>Recreational/pleasure driving (sporty, fun drives)</span>
//                   </label>
//                   <label className="option-tile">
//                     <input
//                       type="checkbox"
//                       checked={responses.usage.includes('Ridesharing (passenger transport)')}
//                       onChange={() =>
//                         handleCheckbox('usage', 'Ridesharing (passenger transport)')
//                       }
//                     />
//                     <span>Ridesharing (passenger transport)</span>
//                   </label>
//                   <label className="option-tile">
//                     <input
//                       type="checkbox"
//                       checked={responses.usage.includes('Outdoor adventures (camping, fishing gear)')}
//                       onChange={() =>
//                         handleCheckbox('usage', 'Outdoor adventures (camping, fishing gear)')
//                       }
//                     />
//                     <span>Outdoor adventures (camping, fishing gear)</span>
//                   </label>
//                   <label className="option-tile">
//                     <input
//                       type="checkbox"
//                       checked={responses.usage.includes('Hauling bulky items (extra cargo space)')}
//                       onChange={() =>
//                         handleCheckbox('usage', 'Hauling bulky items (extra cargo space)')
//                       }
//                     />
//                     <span>Hauling bulky items (extra cargo space)</span>
//                   </label>
//                   <label className="option-tile">
//                     <input
//                       type="checkbox"
//                       checked={responses.usage.includes('Off-road or snowy conditions (rugged, winter-ready)')}
//                       onChange={() =>
//                         handleCheckbox('usage', 'Off-road or snowy conditions (rugged, winter-ready)')
//                       }
//                     />
//                     <span>Off-road or snowy conditions (rugged, winter-ready)</span>
//                   </label>
//                 </div>
//                 <div className="nav-row">
//                   <div />
//                   <button className="next-btn" onClick={nextStep}>
//                     Next
//                   </button>
//                 </div>
//               </>
//             )}

//             {/* Step 2: Car Brand Selection */}
//             {step === 2 && (
//               <>
//                 <h2>Step 2 of 7</h2>
//                 <h3>Which car brand(s) do you like?</h3>
//                 <p className="step-subtitle">Select up to 3 (optional):</p>
//                 <div className="option-list wide-grid brand-scroller">
//                   {brandList.map(brand => (
//                     <label key={brand} className="option-tile">
//                       <input
//                         type="checkbox"
//                         checked={responses.brand.includes(brand)}
//                         onChange={() => handleBrandChange(brand)}
//                       />
//                       <span>{brand}</span>
//                     </label>
//                   ))}
//                 </div>
//                 <div className="nav-row">
//                   <button className="back-btn" onClick={prevStep}>
//                     Back
//                   </button>
//                   <button className="next-btn" onClick={nextStep}>
//                     Next
//                   </button>
//                 </div>
//               </>
//             )}

//             {/* Step 3: Must-Have Features */}
//             {step === 3 && (
//               <>
//                 <h2>Step 3 of 7</h2>
//                 <h3>What are your must-have features?</h3>
//                 <p className="step-subtitle">Select all that apply (optional):</p>
//                 {/* Using "equal-width" so that the longest text sets the bubble width */}
//                 <div className="option-list equal-width">
//                   <label className="option-tile">
//                     <input
//                       type="checkbox"
//                       checked={responses.mustHaves.includes('Wi-Fi connectivity (stream on the go)')}
//                       onChange={() =>
//                         handleCheckbox('mustHaves', 'Wi-Fi connectivity (stream on the go)')
//                       }
//                     />
//                     <span>Wi-Fi connectivity (stream on the go)</span>
//                   </label>
//                   <label className="option-tile">
//                     <input
//                       type="checkbox"
//                       checked={responses.mustHaves.includes('Sunroof/Moonroof (slide open the roof)')}
//                       onChange={() =>
//                         handleCheckbox('mustHaves', 'Sunroof/Moonroof (slide open the roof)')
//                       }
//                     />
//                     <span>Sunroof/Moonroof (slide open the roof)</span>
//                   </label>
//                   <label className="option-tile">
//                     <input
//                       type="checkbox"
//                       checked={responses.mustHaves.includes('Leather seats (ride in style)')}
//                       onChange={() =>
//                         handleCheckbox('mustHaves', 'Leather seats (ride in style)')
//                       }
//                     />
//                     <span>Leather seats (ride in style)</span>
//                   </label>
//                   <label className="option-tile">
//                     <input
//                       type="checkbox"
//                       checked={responses.mustHaves.includes('All-wheel drive (better road traction) (extra grip on any road)')}
//                       onChange={() =>
//                         handleCheckbox('mustHaves', 'All-wheel drive (better road traction) (extra grip on any road)')
//                       }
//                     />
//                     <span>All-wheel drive (better road traction) (extra grip on any road)</span>
//                   </label>
//                   <label className="option-tile">
//                     <input
//                       type="checkbox"
//                       checked={responses.mustHaves.includes('Apple CarPlay/Android Auto (smartphone integration)')}
//                       onChange={() =>
//                         handleCheckbox('mustHaves', 'Apple CarPlay/Android Auto (smartphone integration)')
//                       }
//                     />
//                     <span>Apple CarPlay/Android Auto (smartphone integration)</span>
//                   </label>
//                   <label className="option-tile">
//                     <input
//                       type="checkbox"
//                       checked={responses.mustHaves.includes('Heated Seats (for comfort in cold weather)')}
//                       onChange={() =>
//                         handleCheckbox('mustHaves', 'Heated Seats (for comfort in cold weather)')
//                       }
//                     />
//                     <span>Heated Seats (for comfort in cold weather)</span>
//                   </label>
//                   <label className="option-tile">
//                     <input
//                       type="checkbox"
//                       checked={responses.mustHaves.includes('Blind Spot Monitoring (enhanced safety)')}
//                       onChange={() =>
//                         handleCheckbox('mustHaves', 'Blind Spot Monitoring (enhanced safety)')
//                       }
//                     />
//                     <span>Blind Spot Monitoring (enhanced safety)</span>
//                   </label>
//                 </div>
//                 <div className="nav-row">
//                   <button className="back-btn" onClick={prevStep}>
//                     Back
//                   </button>
//                   <button className="next-btn" onClick={nextStep}>
//                     Next
//                   </button>
//                 </div>
//               </>
//             )}

//             {/* Step 4: Seat Count */}
//             {step === 4 && (
//               <>
//                 <h2>Step 4 of 7</h2>
//                 <h3>How many total seats do you need?</h3>
//                 <p className="step-subtitle">Select one (optional):</p>
//                 <div className="option-list narrow-list">
//                   {seatOptions.map(seatOpt => (
//                     <label key={seatOpt} className="option-tile">
//                       <input
//                         type="radio"
//                         name="seats"
//                         checked={responses.seats === seatOpt}
//                         onChange={() => setResponses(prev => ({ ...prev, seats: seatOpt }))}
//                       />
//                       <span>{seatOpt}</span>
//                     </label>
//                   ))}
//                 </div>
//                 <div className="nav-row">
//                   <button className="back-btn" onClick={prevStep}>
//                     Back
//                   </button>
//                   <button className="next-btn" onClick={nextStep}>
//                     Next
//                   </button>
//                 </div>
//               </>
//             )}

//             {/* Step 5: Preferred Vehicle Type */}
//             {step === 5 && (
//               <>
//                 <h2>Step 5 of 7</h2>
//                 <h3>Preferred type of vehicle?</h3>
//                 <p className="step-subtitle">Select one (optional):</p>
//                 <div className="option-list narrow-list">
//                   {(
//                     responses.seats === '8+ Seats'
//                       ? bodyTypeOptions.filter(bt => bt !== 'Coupe' && bt !== 'Sedan')
//                       : responses.seats === '6-8 Seats'
//                         ? bodyTypeOptions.filter(bt => bt !== 'Coupe')
//                         : bodyTypeOptions
//                   ).map(bt => (
//                     <label key={bt} className="option-tile">
//                       <input
//                         type="radio"
//                         name="bodyType"
//                         checked={responses.bodyType === bt}
//                         onChange={() => setResponses(prev => ({ ...prev, bodyType: bt }))}
//                       />
//                       <span>{bt}</span>
//                     </label>
//                   ))}
//                 </div>
//                 <div className="nav-row">
//                   <button className="back-btn" onClick={prevStep}>
//                     Back
//                   </button>
//                   <button className="next-btn" onClick={nextStep}>
//                     Next
//                   </button>
//                 </div>
//               </>
//             )}

//             {/* Step 6: Power Type */}
//             {step === 6 && (
//               <>
//                 <h2>Step 6 of 7</h2>
//                 <h3>Select your car's energy type</h3>
//                 <p className="step-subtitle">We have options that are both traditional and modern</p>
//                 <div className="option-list narrow-list">
//                   {powerTypeOptions.map(pt => (
//                     <label key={pt} className="option-tile">
//                       <input
//                         type="radio"
//                         name="powerType"
//                         checked={responses.powerType === pt}
//                         onChange={() => setResponses(prev => ({ ...prev, powerType: pt }))}
//                       />
//                       <span>{pt}</span>
//                     </label>
//                   ))}
//                 </div>
//                 <div className="nav-row">
//                   <button className="back-btn" onClick={prevStep}>
//                     Back
//                   </button>
//                   <button className="next-btn" onClick={nextStep}>
//                     Next
//                   </button>
//                 </div>
//               </>
//             )}

//             {/* Step 7: Budget Range */}
//             {step === 7 && (
//               <>
//                 <h2>Step 7 of 7</h2>
//                 <h3>What’s your budget range?</h3>
//                 <p className="step-subtitle">Required:</p>
//                 <div className="option-list narrow-list">
//                   {budgetRanges.map(br => (
//                     <label key={br} className="option-tile">
//                       <input
//                         type="radio"
//                         name="budgetRange"
//                         onChange={() => handleRadio('budgetRange', br)}
//                         checked={
//                           (br === '< $10k' && responses.budgetMax === 10000) ||
//                           (br === '$10k - $20k' && responses.budgetMin === 10000 && responses.budgetMax === 20000) ||
//                           (br === '$20k - $30k' && responses.budgetMin === 20000 && responses.budgetMax === 30000) ||
//                           (br === '$30k - $40k' && responses.budgetMin === 30000 && responses.budgetMax === 40000) ||
//                           (br === '$40k - $50k' && responses.budgetMin === 40000 && responses.budgetMax === 50000) ||
//                           (br === '>$50k' && responses.budgetMin === 50000)
//                         }
//                       />
//                       <span>{br}</span>
//                     </label>
//                   ))}
//                 </div>
//                 <div className="nav-row">
//                   <button className="back-btn" onClick={prevStep}>
//                     Back
//                   </button>
//                   <button
//                     className="next-btn"
//                     onClick={handleSubmit}
//                     disabled={isLoading || responses.budgetMax === 0}
//                   >
//                     {isLoading ? 'Loading...' : 'Submit'}
//                   </button>
//                 </div>
//               </>
//             )}
//           </div>
//         </>
//       )}

//       {chatResponse.length > 0 && (
//         <RecommendationsDisplay
//           recommendations={chatResponse}
//           responses={responses}
//           onStartOver={handleStartOver}
//         />
//       )}

//       <footer className="disclaimer-area">
//         By using Qarby, you agree to our <a href="/terms">Terms</a> and <a href="/privacy">Privacy Policy</a>. Qarby was built to answer questions related to car purchasing, leasing and maintenance.
//       </footer>
//     </div>
//   );
// };

// export default FindYourCar;