// src/components/TermsAndConditions.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
  // Track open/close state for each section
  const [openSections, setOpenSections] = useState({});

  const sections = [
    {
      title: "Introduction",
      content:
        "Welcome to Qarby. These Terms and Conditions govern your use of our website and services. By accessing or using Qarby, you agree to be bound by these terms.",
    },
    {
      title: "Use of the Service",
      content:
        "You agree to use our Service only for lawful purposes and in a manner that does not infringe on the rights of others or restrict their use and enjoyment of the Service.",
    },
    {
      title: "User Accounts",
      content:
        "If you create an account on Qarby, you are responsible for maintaining the confidentiality of your account and password, as well as all activities that occur under your account.",
    },
    {
      title: "Intellectual Property",
      content:
        "All content, trademarks, logos, graphics, and data on Qarby are the property of Qarby or its licensors and are protected by applicable intellectual property laws.",
    },
    {
      title: "Termination",
      content:
        "We reserve the right to terminate or suspend your access to our Service immediately and without notice if you breach these Terms and Conditions.",
    },
    {
      title: "Disclaimer",
      content:
        "The Service is provided on an 'AS IS' and 'AS AVAILABLE' basis without any warranties, express or implied. Qarby does not guarantee the accuracy or reliability of the Service.",
    },
    {
      title: "Limitation of Liability",
      content:
        "In no event shall Qarby, its directors, employees, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of the Service.",
    },
    {
      title: "Changes to These Terms",
      content:
        "We reserve the right to modify these Terms and Conditions at any time. Your continued use of the Service after any changes have been made constitutes your acceptance of the new Terms.",
    },
    {
      title: "Contact Us",
      content:
        'If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:info@qarby.com">info@qarby.com</a>.',
    },
  ];

  const toggleSection = (index) => {
    setOpenSections((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <div className="terms-page">
      <div className="terms-container">
        <h1>Terms and Conditions</h1>
        {sections.map((section, index) => (
          <div
            key={index}
            className={`collapsible-section ${openSections[index] ? "open" : ""}`}
          >
            <div className="section-header" onClick={() => toggleSection(index)}>
              <h2>{section.title}</h2>
              <span className="toggle-icon">
                {openSections[index] ? "-" : "+"}
              </span>
            </div>
            {openSections[index] && (
              <div className="section-content">
                {section.title === "Contact Us" ? (
                  <p
                    dangerouslySetInnerHTML={{ __html: section.content }}
                  ></p>
                ) : (
                  <p>{section.content}</p>
                )}
              </div>
            )}
          </div>
        ))}
        {/* New Footer Section */}
        <footer className="terms-footer">
          <p>
            Have questions? Email us at{" "}
            <a href="mailto:info@qarby.com">info@qarby.com</a>
            <br />
            <br />
            <Link to="/">Home</Link>
          </p>
        </footer>
      </div>
    </div>
  );
};

export default TermsAndConditions;


// // src/components/TermsAndConditions.js

// import React, { useState } from "react";
// import "./TermsAndConditions.css";

// const TermsAndConditions = () => {
//   const [openSections, setOpenSections] = useState({});

//   const sections = [
//     {
//       title: "Introduction",
//       content:
//         "Welcome to Qarby. These Terms and Conditions govern your use of our website and services. By accessing or using Qarby, you agree to be bound by these terms.",
//     },
//     {
//       title: "Use of the Service",
//       content:
//         "You agree to use our Service only for lawful purposes and in a manner that does not infringe on the rights of others or restrict their use and enjoyment of the Service.",
//     },
//     {
//       title: "User Accounts",
//       content:
//         "If you create an account on Qarby, you are responsible for maintaining the confidentiality of your account and password, as well as all activities that occur under your account.",
//     },
//     {
//       title: "Intellectual Property",
//       content:
//         "All content, trademarks, logos, graphics, and data on Qarby are the property of Qarby or its licensors and are protected by applicable intellectual property laws.",
//     },
//     {
//       title: "Termination",
//       content:
//         "We reserve the right to terminate or suspend your access to our Service immediately and without notice if you breach these Terms and Conditions.",
//     },
//     {
//       title: "Disclaimer",
//       content:
//         "The Service is provided on an 'AS IS' and 'AS AVAILABLE' basis without any warranties, express or implied. Qarby does not guarantee the accuracy or reliability of the Service.",
//     },
//     {
//       title: "Limitation of Liability",
//       content:
//         "In no event shall Qarby, its directors, employees, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of the Service.",
//     },
//     {
//       title: "Changes to These Terms",
//       content:
//         "We reserve the right to modify these Terms and Conditions at any time. Your continued use of the Service after any changes have been made constitutes your acceptance of the new Terms.",
//     },
//     {
//       title: "Contact Us",
//       content:
//         'If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:info@qarby.com">info@qarby.com</a>.',
//     },
//   ];

//   const toggleSection = (index) => {
//     setOpenSections((prev) => ({ ...prev, [index]: !prev[index] }));
//   };

//   return (
//     <div className="terms-page">
//       <div className="terms-container">
//         <h1>Terms and Conditions</h1>
//         {sections.map((section, index) => (
//           <div
//             key={index}
//             className={`collapsible-section ${openSections[index] ? "open" : ""}`}
//           >
//             <div className="section-header" onClick={() => toggleSection(index)}>
//               <h2>{section.title}</h2>
//               <span className="toggle-icon">
//                 {openSections[index] ? "-" : "+"}
//               </span>
//             </div>
//             {openSections[index] && (
//               <div className="section-content">
//                 {section.title === "Contact Us" ? (
//                   <p
//                     dangerouslySetInnerHTML={{ __html: section.content }}
//                   ></p>
//                 ) : (
//                   <p>{section.content}</p>
//                 )}
//               </div>
//             )}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default TermsAndConditions;
