import React from "react";
import { Link } from "react-router-dom";
// import purpleCircle from "../assets/purple_circle.png";
import msgIcon from "../assets/msg_icon.png";
import { HeroiconsOutlineSparkles2 } from "../icons/HeroiconsOutlineSparkles2";
import styles from "./FeaturesSection.module.css";

const FeaturesSection = () => {
  return (
    <div className={styles.featuresContainer}>
      <header className={styles.featuresHeader}>
        <h2 className={styles.featuresTitle}>Your go-to for car decisions</h2>
        <p className={styles.featuresSubtitle}>
          Qarby simplifies car buying with trusted information, expert insights, and personalized guidance—making the process clear and stress‑free. And beyond purchase, Qarby is your trusted partner for ongoing maintenance and every step of your car journey.
        </p>
      </header>

      <div className={styles.featuresList}>
        {/* Feature #1: Chat with Qarby */}
        {/* Feature #1: Chat with Qarby */}
<div className={styles.featureCard}>
  <Link to="/askqarby" className={styles.featureLink}>
    <div className={styles.iconContainer}>
      <div className={styles.iconBgAlt}>
        <img src={msgIcon} alt="Message Icon" className={styles.icon} />
      </div>
    </div>
    <div className={styles.cardTitle}>
      Chat with
      <br />
      Qarby
    </div>
    <div className={styles.arrowWrapper}>
      <div className={styles.featureArrowIcon} />
    </div>
  </Link>
</div>


        {/* Feature #2: The Matchmaker */}
        <div className={styles.featureCard}>
          <Link to="/findyourcar" className={styles.featureLink}>
            <div className={styles.iconContainer}>
              <div className={styles.iconBgAlt}>
                <HeroiconsOutlineSparkles2 className={styles.icon} />
              </div>
            </div>
            <div className={styles.cardTitle}>
              The
              <br />
              Matchmaker
            </div>
            <div className={styles.arrowWrapper}>
              <div className={styles.featureArrowIcon} />
            </div>
          </Link>
        </div>


        {/* Feature #3: Your Garage */}
        <div className={styles.featureCard}>
          <Link to="/garage" className={styles.featureLink}>
            <div className={styles.iconContainer}>
              <div className={styles.iconBgAlt}>
                <img
                  className={styles.customIcon}
                  alt="360 View"
                  src="https://cdn.animaapp.com/projects/678a985e0cccaf0892a06cc5/releases/678a99f1cefd91045472e2fe/img/360-view-1.svg"
                />
              </div>
            </div>
            <div className={styles.cardTitle}>
              Your
              <br />
              Garage
            </div>
            <div className={styles.arrowWrapper}>
              <div className={styles.featureArrowIcon} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
