// src/pages/Garage.js
import React, { useEffect, useState } from 'react';
import '../styles/Garage.css';
import { useUser } from '../UserContext';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../loginRequest';
import { carOptions } from '../components/carOptions'; // Import car options for autocomplete
import StarIcon from '../assets/Star-1.png'; // Example star icon

// Helper function to fetch car image using the imageSearch API
const fetchCarImage = async (make, model, year) => {
  const query = `${make} ${model} ${year} car`;
  try {
    const response = await fetch(
      `https://carprojfunctionappnew.azurewebsites.net/api/imagesearch?q=${encodeURIComponent(query)}`
    );
    const data = await response.json();
    if (data.images && data.images.length > 0) {
      return data.images[0]; // Return the first image URL found
    }
  } catch (error) {
    console.error("Error fetching car image:", error);
  }
  return ""; // Return empty string if no image found or error occurs
};

const Garage = () => {
  const { user, setUser } = useUser();
  const { instance } = useMsal();

  const [showSignInPrompt, setShowSignInPrompt] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // The user’s personal car info (recall count removed)
  const [myCar, setMyCar] = useState({
    make: '',
    model: '',
    year: '',
    vin: '',
    lastMaintenance: '',
    imageUrl: ''
  });

  // The user’s favorites from your backend
  const [favorites, setFavorites] = useState([]);

  // Whether to show the form for editing/adding user’s car (as a modal)
  const [showCarForm, setShowCarForm] = useState(false);

  // For "Details" modal in Favorites
  const [selectedFavorite, setSelectedFavorite] = useState(null);

  // Spinner state for image fetching
  const [isImageFetching, setIsImageFetching] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.id) {
        setShowSignInPrompt(true);
        setLoading(false);
        return;
      }
      try {
        const response = await fetch(
          `https://carprojfunctionappnew.azurewebsites.net/api/getfavorites?userId=${encodeURIComponent(user.id)}`
        );
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to fetch favorites');
        }
        const data = await response.json();
        if (data.favorites) setFavorites(data.favorites);
        if (data.myCar) {
          setMyCar({
            make: data.myCar.make || '',
            model: data.myCar.model || '',
            year: data.myCar.year || '',
            vin: data.myCar.vin || '',
            lastMaintenance: data.myCar.lastMaintenance || '',
            imageUrl: data.myCar.imageUrl || ''
          });
        }
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [user]);

  const handleSignIn = async () => {
    try {
      const loginResponse = await instance.loginPopup(loginRequest);
      instance.setActiveAccount(loginResponse.account);
      const { account } = loginResponse;
      const name = account.idTokenClaims?.name || account.username;
      const email = account.idTokenClaims?.email || account.username;
      setUser({
        id: account.localAccountId,
        displayName: name,
        email: email
      });
      setShowSignInPrompt(false);
      setError(null);
      setLoading(true);
    } catch (loginError) {
      console.error("Popup login error:", loginError);
      setError("Failed to sign in. Please try again.");
    }
  };

  const handleCarInputChange = (e) => {
    const { name, value } = e.target;
    setMyCar((prev) => ({ ...prev, [name]: value }));
  };

  const handleCarSubmit = async () => {
    if (!user?.id) {
      alert("User not logged in. Please sign in first.");
      return;
    }
    
    setIsImageFetching(true);
    try {
      // First, fetch the car image URL based on make, model, and year
      const newImageUrl = await fetchCarImage(myCar.make, myCar.model, myCar.year);
      
      // Then, include the imageUrl in the POST request to save the car info
      const postResponse = await fetch(
        `https://carprojfunctionappnew.azurewebsites.net/api/getfavorites?userId=${encodeURIComponent(user.id)}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            make: myCar.make,
            model: myCar.model,
            year: myCar.year,
            vin: myCar.vin,
            lastMaintenance: myCar.lastMaintenance,
            imageUrl: newImageUrl // include the fetched image URL
          })
        }
      );
      if (!postResponse.ok) {
        const errorData = await postResponse.json();
        throw new Error(errorData.error || 'Failed to save car info');
      }
      const result = await postResponse.json();
      console.log("Car info saved, doc:", result);
      
      // Update local state with the saved car info
      if (result.myCar) {
        setMyCar(result.myCar);
      }
      
      alert("Your car info has been saved!");
      setShowCarForm(false);
    } catch (err) {
      console.error(err);
      alert("Failed to save car info. " + err.message);
    } finally {
      setIsImageFetching(false);
    }
  };

  const handleDeleteFavorite = async (fav) => {
    if (!user?.id) {
      alert("User not logged in. Please sign in first.");
      return;
    }
    const confirmDelete = window.confirm(`Are you sure you want to remove ${fav.make} ${fav.model}?`);
    if (!confirmDelete) return;
    try {
      const delResponse = await fetch(
        `https://carprojfunctionappnew.azurewebsites.net/api/getfavorites?userId=${encodeURIComponent(user.id)}`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            make: fav.make,
            model: fav.model,
            timestamp: fav.timestamp
          })
        }
      );
      if (!delResponse.ok) {
        const errData = await delResponse.json();
        throw new Error(errData.error || "Failed to delete favorite");
      }
      const result = await delResponse.json();
      alert("Favorite deleted!");
      if (result.favorites) setFavorites(result.favorites);
      if (selectedFavorite &&
          selectedFavorite.make === fav.make &&
          selectedFavorite.model === fav.model &&
          selectedFavorite.timestamp === fav.timestamp) {
        setSelectedFavorite(null);
      }
    } catch (err) {
      console.error(err);
      alert("Could not delete favorite. " + err.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (showSignInPrompt) {
    return (
      <div className="garage-signin-prompt">
        <h2>Please Sign In</h2>
        <p>We use your login to manage your Garage data.</p>
        <button onClick={handleSignIn}>Sign In</button>
      </div>
    );
  }
  if (error) return <div>Error: {error}</div>;

  const hasCarInfo = myCar.make && myCar.model;

  return (
    <div className="garage-wrapper">
      {/* Car Hero Area: Image and Details on the Same Row */}
      <div className="car-hero-container">
        <div className="car-hero-image">
          {myCar.imageUrl ? (
            <img
              src={myCar.imageUrl}
              alt={`${myCar.make} ${myCar.model}`}
              className="hero-car-img"
            />
          ) : isImageFetching ? (
            <div className="loader-circle" />
          ) : (
            <img
              src="https://cdn.animaapp.com/projects/demo-car.png"
              alt="Placeholder"
              className="hero-car-img"
            />
          )}
        </div>
        <div className="car-hero-details">
          {hasCarInfo ? (
            <>
              <h2>{myCar.make} {myCar.model} {myCar.year}</h2>
              <p>VIN NUMBER: {myCar.vin || 'N/A'}</p>
              <p>LAST MAINTENANCE: {myCar.lastMaintenance || 'N/A'}</p>
              <button className="edit-car-btn" onClick={() => setShowCarForm(true)}>
                Edit Car
              </button>
            </>
          ) : (
            <div className="no-car-info" onClick={() => setShowCarForm(true)}>
              <p>Click here to add your car details</p>
            </div>
          )}
        </div>
      </div>

      {/* Car Form Modal (Popup) */}
      {showCarForm && (
        <div className="car-form-modal-overlay" onClick={() => setShowCarForm(false)}>
          <div className="car-form-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="car-form-modal-close" onClick={() => setShowCarForm(false)}>&times;</button>
            <h4>{hasCarInfo ? 'Update' : 'Enter'} Your Car Details</h4>
            <div className="car-form-row">
              <label htmlFor="make">Make:</label>
              <div className="input-container">
                <input
                  id="make"
                  type="text"
                  name="make"
                  value={myCar.make}
                  onChange={handleCarInputChange}
                  list="makeOptions"
                />
                <datalist id="makeOptions">
                  {carOptions.map((option) => (
                    <option key={option.make} value={option.make} />
                  ))}
                </datalist>
              </div>
            </div>
            <div className="car-form-row">
              <label htmlFor="model">Model:</label>
              <div className="input-container">
                <input
                  id="model"
                  type="text"
                  name="model"
                  value={myCar.model}
                  onChange={handleCarInputChange}
                  list="modelOptions"
                />
                <datalist id="modelOptions">
                  {carOptions.find(
                    option => option.make.toLowerCase() === myCar.make.toLowerCase()
                  )?.models.map((model) => (
                    <option key={model} value={model} />
                  ))}
                </datalist>
              </div>
            </div>
            <div className="car-form-row">
              <label htmlFor="year">Year:</label>
              <div className="input-container">
                <input
                  id="year"
                  type="text"
                  name="year"
                  value={myCar.year}
                  onChange={handleCarInputChange}
                  list="yearOptions"
                />
                <datalist id="yearOptions">
                  {Array.from({ length: 2025 - 1990 + 1 }, (_, i) => (
                    <option key={1990 + i} value={1990 + i} />
                  ))}
                </datalist>
              </div>
            </div>
            <div className="car-form-row">
              <label htmlFor="vin">VIN:</label>
              <div className="input-container">
                <input
                  id="vin"
                  type="text"
                  name="vin"
                  value={myCar.vin}
                  onChange={handleCarInputChange}
                />
              </div>
            </div>
            <div className="car-form-row">
              <label htmlFor="lastMaintenance">Last Maintenance:</label>
              <div className="input-container">
                <input
                  id="lastMaintenance"
                  type="text"
                  name="lastMaintenance"
                  value={myCar.lastMaintenance}
                  onChange={handleCarInputChange}
                />
              </div>
            </div>
            <div className="car-form-btn-row">
              <button onClick={handleCarSubmit}>Save</button>
              <button onClick={() => setShowCarForm(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Favorites Section */}
      <div className="favorites-section">
        <div className="favorites-background-rect" />
        <div className="favorites-content-rect">
          <div className="favorites-title-row">
            <h2>FAVORITES</h2>
            <img src={StarIcon} alt="Star" className="favorites-star" />
          </div>
          <div className="favorites-scroll-area">
            {favorites.length === 0 ? (
              <p className="no-favorites-msg">No favorites yet.</p>
            ) : (
              favorites.map((fav, idx) => (
                <div key={idx} className="favorite-entry">
                  <div className="fav-img-box">
                    {fav.imageUrl ? (
                      <img src={fav.imageUrl} alt={`${fav.make} ${fav.model}`} />
                    ) : (
                      <div className="fav-no-image">No image</div>
                    )}
                  </div>
                  <div className="fav-text">
                    <h4>{fav.make} {fav.model}</h4>
                  </div>
                  <div className="fav-actions">
                    <button onClick={() => setSelectedFavorite(fav)}>Details</button>
                    <button className="delete-btn" onClick={() => handleDeleteFavorite(fav)}>Delete</button>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {/* Bottom Icons */}
      <div className="garage-bottom-icons">
        <div className="icon-circle">Chat</div>
        <div className="icon-circle">Mic</div>
        <div className="icon-circle">Sparkles</div>
        <div className="icon-circle">Garage</div>
        <div className="icon-circle">Profile</div>
      </div>

      {/* Garage Footer */}
      <footer className="garage-footer">
        By using Qarby, you agree to our <a href="/termsandconditions">Terms</a> and <a href="/privacy">Privacy Policy</a>. Qarby was built to answer questions related to car purchasing, leasing and maintenance.
      </footer>

      {/* Details Modal */}
      {selectedFavorite && (
        <div className="details-modal-overlay" onClick={() => setSelectedFavorite(null)}>
          <div className="details-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="details-modal-close" onClick={() => setSelectedFavorite(null)}>&times;</button>
            <h2>{selectedFavorite.make} {selectedFavorite.model}</h2>
            {selectedFavorite.whyIdeal && selectedFavorite.whyIdeal.length > 0 && (
              <>
                <h4 className="section-heading">Why It’s Ideal</h4>
                <ul>
                  {selectedFavorite.whyIdeal.map((why, i) => (
                    <li key={i}>{why}</li>
                  ))}
                </ul>
              </>
            )}
            {selectedFavorite.keyFeatures && selectedFavorite.keyFeatures.length > 0 && (
              <>
                <h4>Key Features</h4>
                <ul>
                  {selectedFavorite.keyFeatures.map((feat, i) => (
                    <li key={i}>{feat}</li>
                  ))}
                </ul>
              </>
            )}
            {selectedFavorite.quickSpecs && (
              <>
                <h4>Quick Specs</h4>
                <ul>
                  {selectedFavorite.quickSpecs.priceRange && (
                    <li><strong className="spec-label">Price Range:</strong> {selectedFavorite.quickSpecs.priceRange}</li>
                  )}
                  {selectedFavorite.quickSpecs.seats && (
                    <li><strong className="spec-label">Seats:</strong> {selectedFavorite.quickSpecs.seats}</li>
                  )}
                  {selectedFavorite.quickSpecs.fuelEconomy && (
                    <li><strong className="spec-label">Fuel Economy:</strong> {selectedFavorite.quickSpecs.fuelEconomy}</li>
                  )}
                  {selectedFavorite.quickSpecs.warranty && (
                    <li><strong className="spec-label">Warranty:</strong> {selectedFavorite.quickSpecs.warranty}</li>
                  )}
                </ul>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Garage;


// // src/pages/Garage.js
// import React, { useEffect, useState } from 'react';
// import '../styles/Garage.css';
// import { useUser } from '../UserContext';
// import { useMsal } from '@azure/msal-react';
// import { loginRequest } from '../loginRequest';

// import StarIcon from '../assets/Star-1.png'; // Example star icon

// const Garage = () => {
//   const { user, setUser } = useUser();
//   const { instance } = useMsal();

//   const [showSignInPrompt, setShowSignInPrompt] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   // The user’s personal car info
//   const [myCar, setMyCar] = useState({
//     make: '',
//     model: '',
//     year: '',
//     vin: '',
//     lastMaintenance: '',
//     recallsCount: '',
//     imageUrl: ''
//   });

//   // The user’s favorites from your backend
//   const [favorites, setFavorites] = useState([]);

//   // Whether to show the form for editing/adding user’s car
//   const [showCarForm, setShowCarForm] = useState(false);

//   // Are we fetching a new image? (spinner)
//   const [isImageFetching, setIsImageFetching] = useState(false);

//   // For "Details" modal
//   const [selectedFavorite, setSelectedFavorite] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       if (!user?.id) {
//         setShowSignInPrompt(true);
//         setLoading(false);
//         return;
//       }

//       try {
//         const response = await fetch(
//           `https://carprojfunctionappnew.azurewebsites.net/api/getfavorites?userId=${encodeURIComponent(user.id)}`
//         );
//         if (!response.ok) {
//           const errorData = await response.json();
//           throw new Error(errorData.error || 'Failed to fetch favorites');
//         }

//         const data = await response.json();
//         if (data.favorites) setFavorites(data.favorites);
//         if (data.myCar) {
//           setMyCar({
//             make: data.myCar.make || '',
//             model: data.myCar.model || '',
//             year: data.myCar.year || '',
//             vin: data.myCar.vin || '',
//             lastMaintenance: data.myCar.lastMaintenance || '',
//             recallsCount: data.myCar.recallsCount || '',
//             imageUrl: data.myCar.imageUrl || ''
//           });
//         }

//         setLoading(false);
//       } catch (err) {
//         setError(err.message);
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, [user]);

//   const handleSignIn = async () => {
//     try {
//       const loginResponse = await instance.loginPopup(loginRequest);
//       instance.setActiveAccount(loginResponse.account);

//       const { account } = loginResponse;
//       const name = account.idTokenClaims?.name || account.username;
//       const email = account.idTokenClaims?.email || account.username;

//       setUser({
//         id: account.localAccountId,
//         displayName: name,
//         email: email
//       });

//       setShowSignInPrompt(false);
//       setError(null);
//       setLoading(true);
//     } catch (loginError) {
//       console.error("Popup login error:", loginError);
//       setError("Failed to sign in. Please try again.");
//     }
//   };

//   // Car input changes
//   const handleCarInputChange = (e) => {
//     const { name, value } = e.target;
//     setMyCar((prev) => ({ ...prev, [name]: value }));
//   };

//   // Save car => POST => refetch
//   const handleCarSubmit = async () => {
//     if (!user?.id) {
//       alert("User not logged in. Please sign in first.");
//       return;
//     }

//     if (!myCar.imageUrl) setIsImageFetching(true);

//     try {
//       const postResponse = await fetch(
//         `https://carprojfunctionappnew.azurewebsites.net/api/getfavorites?userId=${encodeURIComponent(user.id)}`,
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({
//             make: myCar.make,
//             model: myCar.model,
//             year: myCar.year,
//             vin: myCar.vin,
//             lastMaintenance: myCar.lastMaintenance,
//             recallsCount: myCar.recallsCount
//           })
//         }
//       );
//       if (!postResponse.ok) {
//         const errorData = await postResponse.json();
//         throw new Error(errorData.error || 'Failed to save car info');
//       }

//       const result = await postResponse.json();
//       console.log("Car info saved, doc:", result);

//       // Re-fetch doc
//       const refreshed = await fetch(
//         `https://carprojfunctionappnew.azurewebsites.net/api/getfavorites?userId=${encodeURIComponent(user.id)}`
//       );
//       const newDoc = await refreshed.json();

//       if (newDoc.favorites) setFavorites(newDoc.favorites);
//       if (newDoc.myCar) {
//         setMyCar({
//           make: newDoc.myCar.make || '',
//           model: newDoc.myCar.model || '',
//           year: newDoc.myCar.year || '',
//           vin: newDoc.myCar.vin || '',
//           lastMaintenance: newDoc.myCar.lastMaintenance || '',
//           recallsCount: newDoc.myCar.recallsCount || '',
//           imageUrl: newDoc.myCar.imageUrl || ''
//         });
//       }

//       alert("Your car info has been saved!");
//       setShowCarForm(false);
//     } catch (err) {
//       console.error(err);
//       alert("Failed to save car info. " + err.message);
//     } finally {
//       setIsImageFetching(false);
//     }
//   };

//   // Delete favorite => call the function with DELETE
//   const handleDeleteFavorite = async (fav) => {
//     if (!user?.id) {
//       alert("User not logged in. Please sign in first.");
//       return;
//     }
//     const confirm = window.confirm(`Are you sure you want to remove ${fav.make} ${fav.model}?`);
//     if (!confirm) return;

//     try {
//       const delResponse = await fetch(
//         `https://carprojfunctionappnew.azurewebsites.net/api/getfavorites?userId=${encodeURIComponent(user.id)}`,
//         {
//           method: "DELETE",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({
//             make: fav.make,
//             model: fav.model,
//             timestamp: fav.timestamp // The unique identifier
//           })
//         }
//       );
//       if (!delResponse.ok) {
//         const errData = await delResponse.json();
//         throw new Error(errData.error || "Failed to delete favorite");
//       }
//       const result = await delResponse.json();
//       alert("Favorite deleted!");

//       // Update local favorites from result
//       if (result.favorites) setFavorites(result.favorites);

//       // If the currently open details modal was the one we deleted, close it
//       if (selectedFavorite && selectedFavorite.make === fav.make &&
//           selectedFavorite.model === fav.model &&
//           selectedFavorite.timestamp === fav.timestamp) {
//         setSelectedFavorite(null);
//       }
//     } catch (err) {
//       console.error(err);
//       alert("Could not delete favorite. " + err.message);
//     }
//   };

//   // If user wants details => store in selectedFavorite => we can show a modal
//   // const handleViewDetails = (fav) => {
//   //   setSelectedFavorite(fav);
//   // };

//   if (loading) return <div>Loading...</div>;
//   if (showSignInPrompt) {
//     return (
//       <div className="garage-signin-prompt">
//         <h2>Please Sign In</h2>
//         <p>We use your login to manage your Garage data.</p>
//         <button onClick={handleSignIn}>Sign In</button>
//       </div>
//     );
//   }
//   if (error) return <div>Error: {error}</div>;

//   const hasCarInfo = myCar.make && myCar.model;

//   return (
//     <div className="garage-wrapper">
//       {/* Car hero area */}
//       <div className="car-hero-container">
//         <div className="car-hero-image">
//           {myCar.imageUrl ? (
//             <img
//               src={myCar.imageUrl}
//               alt={`${myCar.make} ${myCar.model}`}
//               className="hero-car-img"
//             />
//           ) : isImageFetching ? (
//             <div className="loader-circle" />
//           ) : (
//             <img
//               src="https://cdn.animaapp.com/projects/demo-car.png"
//               alt="Placeholder"
//               className="hero-car-img"
//             />
//           )}
//         </div>

//         <div className="car-hero-details">
//           {hasCarInfo ? (
//             <>
//               <h2>{myCar.make} {myCar.model} {myCar.year}</h2>
//               <p>VIN NUMBER: {myCar.vin || 'N/A'}</p>
//               <p>LAST MAINTENANCE DONE: {myCar.lastMaintenance || 'N/A'}</p>
//               <p>RECALLS: {myCar.recallsCount || 0} RECALLS ON THIS CAR</p>

//               <button className="edit-car-btn" onClick={() => setShowCarForm(true)}>
//                 Edit Car
//               </button>
//             </>
//           ) : (
//             <div
//               className="no-car-info"
//               onClick={() => setShowCarForm(true)}
//             >
//               <p>Click here to add your car details</p>
//             </div>
//           )}
//         </div>
//       </div>

//       {/* Car form if editing */}
//       {showCarForm && (
//         <div className="car-form-container">
//           <h4>{hasCarInfo ? 'Update' : 'Enter'} Your Car Details</h4>
//           <label>
//             Make:
//             <input
//               type="text"
//               name="make"
//               value={myCar.make}
//               onChange={handleCarInputChange}
//             />
//           </label>
//           <label>
//             Model:
//             <input
//               type="text"
//               name="model"
//               value={myCar.model}
//               onChange={handleCarInputChange}
//             />
//           </label>
//           <label>
//             Year:
//             <input
//               type="text"
//               name="year"
//               value={myCar.year}
//               onChange={handleCarInputChange}
//             />
//           </label>
//           <label>
//             VIN:
//             <input
//               type="text"
//               name="vin"
//               value={myCar.vin}
//               onChange={handleCarInputChange}
//             />
//           </label>
//           <label>
//             Last Maintenance:
//             <input
//               type="text"
//               name="lastMaintenance"
//               value={myCar.lastMaintenance}
//               onChange={handleCarInputChange}
//             />
//           </label>
//           <label>
//             Recalls Count:
//             <input
//               type="text"
//               name="recallsCount"
//               value={myCar.recallsCount}
//               onChange={handleCarInputChange}
//             />
//           </label>

//           <div className="car-form-btn-row">
//             <button onClick={handleCarSubmit}>Save</button>
//             <button onClick={() => setShowCarForm(false)}>Cancel</button>
//           </div>
//         </div>
//       )}

//       {/* Favorites Section */}
//       <div className="favorites-section">
//         <div className="favorites-background-rect" />
        
//         <div className="favorites-content-rect">
//           <div className="favorites-title-row">
//             <h2>FAVORITES</h2>
//             <img src={StarIcon} alt="Star" className="favorites-star" />
//           </div>

//           <div className="favorites-scroll-area">
//             {favorites.length === 0 ? (
//               <p className="no-favorites-msg">No favorites yet.</p>
//             ) : (
//               favorites.map((fav, idx) => (
//                 <div key={idx} className="favorite-entry">
//                   <div className="fav-img-box">
//                     {fav.imageUrl ? (
//                       <img
//                         src={fav.imageUrl}
//                         alt={`${fav.make} ${fav.model}`}
//                       />
//                     ) : (
//                       <div className="fav-no-image">No image</div>
//                     )}
//                   </div>
//                   <div className="fav-text">
//                     <h4>{fav.make} {fav.model}</h4>
//                     {/* Possibly a snippet or fallback */}
//                     {/* <p>{fav.description || 'No description provided.'}</p> */}
//                   </div>

//                   {/* "Details" => open modal; "Delete" => remove from favorites */}
//                   <div style={{ position: 'absolute', right: 16, top: '50%', transform: 'translateY(-50%)' }}>
//                     <button
//                       style={{ marginRight: 8 }}
//                       onClick={() => setSelectedFavorite(fav)}
//                     >
//                       Details
//                     </button>
//                     <button
//                       style={{ backgroundColor: '#d9534f', color: '#fff', padding: '6px 10px', borderRadius: 6, border: 'none' }}
//                       onClick={() => handleDeleteFavorite(fav)}
//                     >
//                       Delete
//                     </button>
//                   </div>
//                 </div>
//               ))
//             )}
//           </div>
//         </div>
//       </div>

//       {/* The bottom icons */}
//       <div className="garage-bottom-icons">
//         <div className="icon-circle">Chat</div>
//         <div className="icon-circle">Mic</div>
//         <div className="icon-circle">Sparkles</div>
//         <div className="icon-circle">Garage</div>
//         <div className="icon-circle">Profile</div>
//       </div>

//       <footer className="garage-footer">
//         By using Qarby, you agree to our <a href="/terms">Terms</a> and{' '}
//         <a href="/privacy">Privacy Policy</a>. Qarby was built to answer questions
//         related to car purchasing, leasing and maintenance.
//       </footer>

//       {/* The "Details" modal if we have a selectedFavorite */}
//       {selectedFavorite && (
//         <div className="details-modal-overlay" onClick={() => setSelectedFavorite(null)}>
//           <div
//             className="details-modal-content"
//             onClick={(e) => e.stopPropagation()}
//           >
//             <button
//               className="details-modal-close"
//               onClick={() => setSelectedFavorite(null)}
//             >
//               &times;
//             </button>

//             {/* Title: e.g. "Toyota Camry" */}
//             <h2>{selectedFavorite.make} {selectedFavorite.model}</h2>
//             {/* If stored a "year" or "price"? */}
//             {/* {selectedFavorite.price && <p>Approx. Price: ${selectedFavorite.price}</p>} */}

//             {/* If we have the extended fields: whyIdeal, keyFeatures, quickSpecs, etc. */}
//             {selectedFavorite.whyIdeal && selectedFavorite.whyIdeal.length > 0 && (
//               <>
//                 <h4 className="section-heading" >Why It’s Ideal</h4>
//                 <ul>
//                   {selectedFavorite.whyIdeal.map((why, i) => (
//                     <li key={i}>{why}</li>
//                   ))}
//                 </ul>
//               </>
//             )}
//             {selectedFavorite.keyFeatures && selectedFavorite.keyFeatures.length > 0 && (
//               <>
//                 <h4>Key Features</h4>
//                 <ul>
//                   {selectedFavorite.keyFeatures.map((feat, i) => (
//                     <li key={i}>{feat}</li>
//                   ))}
//                 </ul>
//               </>
//             )}
//             {selectedFavorite.quickSpecs && (
//               <>
//                 <h4>Quick Specs</h4>
//                 <ul>
//                   {selectedFavorite.quickSpecs.priceRange && (
//                     <li><strong className="spec-label">Price Range:</strong> {selectedFavorite.quickSpecs.priceRange}</li>
//                   )}
//                   {selectedFavorite.quickSpecs.seats && (
//                     <li><strong className="spec-label">Seats:</strong> {selectedFavorite.quickSpecs.seats}</li>
//                   )}
//                   {selectedFavorite.quickSpecs.fuelEconomy && (
//                     <li><strong className="spec-label">Fuel Economy:</strong> {selectedFavorite.quickSpecs.fuelEconomy}</li>
//                   )}
//                   {selectedFavorite.quickSpecs.warranty && (
//                     <li><strong className="spec-label">Warranty:</strong> {selectedFavorite.quickSpecs.warranty}</li>
//                   )}
//                 </ul>
//               </>
//             )}

//             {/* ...anything else you saved in the favorite doc... */}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Garage;