/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeroiconsOutlineArrowUpRight6 = ({ className }) => {
  return (
    <svg
      className={`heroicons-outline-arrow-up-right-6 ${className}`}
      fill="none"
      height="26"
      viewBox="0 0 26 26"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_3770_3)">
        <path
          className="path"
          d="M13.0215 5.28611V20.7139M13.0215 20.7139L18.8069 14.9285M13.0215 20.7139L7.23604 14.9285"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.45455"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_3770_3">
          <rect
            className="rect"
            fill="white"
            height="17.4545"
            transform="translate(25.3638 13) rotate(135)"
            width="17.4545"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
